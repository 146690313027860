
.Pagination{
    text-align: center;
    .page-btn {
      background: #fff;
      padding: 0 10px;
      border: 1px solid #ccc;
      display: inline-block;
      height: 27px;
      line-height: 27px;
      outline: none;
      cursor: pointer;
      vertical-align: middle;
      body:not(.ltr) &{
        font-family: vazir;      
      }  
      body.ltr &{
        font-family: opensans,sans-serif;      
      }      
      min-width: 0;
      color: $blueLink;
      font-weight: 600;
      &.clicked{
        color: #000;
        background: #fff;
        cursor: default;
        &:hover,&:focus{
          color: #000;
          background: #fff;
        }
      }
      &.disabled{
        color: #bbb;
        background: #fff;
        cursor: no-drop;
        &:hover,&:focus{
          color: #bbb;
          background: #fff;
        }        
      }
      &:hover{
        background: $blueLink;
        color: #fff;
      }
    }
    .prev-btn{
      body:not(.ltr) &{
        border-radius: 0 3px 3px 0;
      }
      body.ltr &{
        border-radius: 3px 0 0 3px;
      } 
      font-weight: 600;      
      &::before{
        content: "";
        display: inline-block;
        width:5px;
        height:5px;
        border: 1px solid;
        vertical-align: middle;
        @include transform(rotate(45deg));
        body:not(.ltr) &{
          border-width: 2px 2px 0 0 ;
          margin-left: 5px;
        }
        body.ltr &{
          border-width: 0 0 2px 2px;
          margin-right: 5px;
        }         

      }
    }
    .next-btn{
      body:not(.ltr) &{
        border-radius: 3px 0 0 3px;
      }  
      body.ltr &{
        border-radius: 0 3px 3px 0;
      }      
      border-width: 1px;
      font-weight: 600;
      &::after{
        content: "";
        display: inline-block;
        width:5px;
        height:5px;
        border: 1px solid;
        vertical-align: middle;
        @include transform(rotate(45deg));
        body:not(.ltr) &{
          border-width: 0 0 2px 2px;
          margin-right: 5px;
        }  
        body.ltr &{
          border-width: 2px 2px 0 0 ;
          margin-left: 5px;
        }
      }      
    }

}