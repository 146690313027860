@font-face {
  font-family: "vazir";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local("vazir"),
    url("../Fonts/vazir/Vazir-Light-FD.woff2") format("woff2"); /* Chrome 26+, Opera 23+, Firefox 39+ */
}
@font-face {
  font-family: "vazir";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local("vazir"), url("../Fonts/vazir/Vazir-FD.woff2") format("woff2"); /* Chrome 26+, Opera 23+, Firefox 39+ */
}
@font-face {
  font-family: "vazir";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("vazir"),
    url("../Fonts/vazir/Vazir-Medium-FD.woff2") format("woff2"); /* Chrome 26+, Opera 23+, Firefox 39+ */
}
@font-face {
  font-family: "vazir";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: local("vazir"), url("../Fonts/vazir/Vazir-Bold-FD.woff2") format("woff2"); /* Chrome 26+, Opera 23+, Firefox 39+ */
}
@font-face {
  font-family: "opensans";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local("opensans"),
    url("../Fonts/Open_Sans/open-sans-v17-latin-regular.woff2") format("woff2"); /* Chrome 26+, Opera 23+, Firefox 39+ */
}
@font-face {
  font-family: "opensans";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: local("opensans"),
    url("../Fonts/Open_Sans/open-sans-v17-latin-700.woff2") format("woff2"); /* Chrome 26+, Opera 23+, Firefox 39+ */
}
@font-face {
  font-family: "opensans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("opensans"),
    url("../Fonts/Open_Sans/open-sans-v17-latin-600.woff2") format("woff2"); /* Chrome 26+, Opera 23+, Firefox 39+ */
}
@font-face {
  font-family: "opensans";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("opensans"),
    url("../Fonts/Open_Sans/open-sans-v17-latin-800.woff2") format("woff2"); /* Chrome 26+, Opera 23+, Firefox 39+ */
}
@font-face {
  font-family: "opensans";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local("opensans"),
    url("../Fonts/Open_Sans/open-sans-v17-latin-300.woff2") format("woff2"); /* Chrome 26+, Opera 23+, Firefox 39+ */
}
@font-face {
  font-family: "naskh";
  src: url("../Fonts/NotoNaskhArabic/NotoNaskhArabicUI-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "naskh";
  src: url("../Fonts/NotoNaskhArabic/NotoNaskhArabicUI-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "sourceSans";
  src: url("../Fonts/source-sans/SourceSansPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "sourceSans";
  src: url("../Fonts/source-sans/SourceSansPro-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "sourceSans";
  src: url("../Fonts/source-sans/SourceSansPro-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "shabnam";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local("shabnam"), url("../Fonts/shabnam/Shabnam.woff2") format("woff2"); /* Chrome 26+, Opera 23+, Firefox 39+ */
}
@font-face {
  font-family: "shabnam";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("shabnam"),
    url("../Fonts/shabnam/Shabnam-Medium.woff2") format("woff2"); /* Chrome 26+, Opera 23+, Firefox 39+ */
}
@font-face {
  font-family: "shabnam";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: local("shabnam"), url("../Fonts/shabnam/Shabnam-Bold.woff2") format("woff2"); /* Chrome 26+, Opera 23+, Firefox 39+ */
}

$roboto_condensedbold: "roboto_condensedbold";
$roboto_condensedbold_italic: "roboto_condensedbold_italic";
$roboto_condenseditalic: "roboto_condenseditalic";
$roboto_condensed_lightregular: "roboto_condensed_lightregular";
$roboto_condensed_lightitalic: "roboto_condensed_lightitalic";
$roboto_condensedregular: "roboto_condensedregular";
$navbar: 60px;
$PaddingForm: 70px;
$baseColor: #0161a1;
$titrFont: "Anton", sans-serif;
$font: vazir, sans-serif;
$arfont: naskh, sans-serif;
$fontEn: opensans, "sans-serif";
$yellow: #fcb714;
$naminTravelYellow : #feba01;
$ayanAirYellow : #e8ba0a;
$ayanAirBlue: #2978b6;
$ayanAirRed: #dc3138;
$style3Yellow: #cfa630;
$style3YellowHover: #f1c339;
$style3YellowPale: #d9b95c;
$style3YellowPaleHover: #f1c339;
$style3blue:#2b2e7f;
$style3blueHover:#343798;
$naminTravelDarkBlue : #00347d;
$orangeText: #ea651a;
$red: #ff5722;
$purple: #ef4050;
$purpleLight: #f25a67;
$green: rgb(29, 172, 8);
$greenLight: #e5f7f4;
$text: #464545;
$gray: rgb(119, 119, 119);

$large: 1101px;
$large_: 1100px;
$medium: 850px;
$medium_: 849px;
$small: 501px;
$small_: 500px;

$darkBlue: #002144;
$blueFooter: #003057;
$backGroundColor: #183b4e;
$blueLink: #23a5b9;
$blueLight: #54a7de;
$blueLinkHover: #1c8697;
$border-color: #bcc7c4;
$boxshadow1: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
$boxshadow2: 0 1px 3px 0 rgba(0, 0, 0, 0.5), 2px 2px 10px 0 rgba(0, 0, 0, 0.4);
.row {
  display: flex;
  .col {
    &.col-12 {
      width: 100%;
    }
    &.col-6 {
      width: 50%;
    }

    padding: 0 2px;
  }
}
//mixins:
@mixin transition($val) {
  -o-transition: $val;
  -moz-transition: $val;
  -webkit-transition: $val;
  transition: $val;
}
@mixin box-shadow($val) {
  -moz-box-shadow: $val;
  -webkit-box-shadow: $val;
  box-shadow: $val;
}
@mixin transform($val) {
  -webkit-transform: $val;
  -moz-transform: $val;
  -o-transform: $val;
  -ms-transform: $val;
  transform: $val;
}
@mixin border-radius($val) {
  -webkit-border-radius: $val;
  -moz-border-radius: $val;
  -o-border-radius: $val;
  -ms-border-radius: $val;
  border-radius: $val;
}
