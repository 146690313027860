@import "./base";

.style-3 {
  @media screen and (min-width : $medium){    
    .searchBar button.searchBtn {
      margin-top: 26px;
    }
  }
  .search-form-wrapper .search-box-holder .form-group .form-select.nationality-select {
    border: 1px solid #eee;
    outline: none;
  }
  .main-content{
    position: relative;
    z-index: 1;
  }
  .header-message {
    padding: 10px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    background: #fff;
    color: #de1010;
    border-bottom: 5px solid #de1010;
    @media screen and (min-width:$medium){
      font-size: 17px;
    }
    svg{
      fill: currentColor;
      display: inline-block;
      width: 25px;
      height: 25px;
      vertical-align: middle;
      margin-left: 5px;
    }
  }
  .button,
  .button[type="submit"] {
    &.color-1-btn,
    &.blue-btn{
      background: $style3blue;
      &:hover {
        @media (hover: hover) {
          background: $style3blueHover;
        }
      }
      &:focus{
        background: $style3blueHover;
      }
      &[disabled]{
        background-color: #bbb;
      }
      &.loading-btn,&.loading-btn[disabled]{
        background-color: #bbb;
      }      
    }
  }
  .navbar{
    padding-bottom: 0;
  }

  .yellow{
    color: $style3Yellow;
  }
  .blue{
    color: $style3blue;
  }

  //start ------- Header
  .style3-top-header {
    background-color: #e0e0e0;
    padding: 5px 0;
    .link-style-btn{
      display: inline-block;
      font-weight: bold;
      color: $style3blue;
      font-size: 15px;
      line-height: 22px;
      height: 22px;
    }
    .phone-number{
      border-right: 1px solid;
      margin-right: 10px;
      padding-right: 10px;
    }
  }
  //End ------ Header

  .home-search-section {
    display: flex;
    flex-direction: column;
    .style3-bg-airplane {
      flex-grow: 1;
      overflow: hidden;
      position: relative;
  }

    @media screen and (min-width : $medium){
      min-height: calc(100vh - 164px);  
      padding: 15px 0;   
    }
    @media screen and (min-width : $large){
      padding: 50px 0;   
    }
    @media screen and (min-width : 1400px){
      padding: 70px 0;   
    }
  }
  .Home.package .home-search-section {
    @media screen and (min-width : $medium){
      padding-bottom: 0;  
      &::before{
        content: "";
        display: block;
        background: linear-gradient(0deg, transparent, #5d6170a1);
        top: 0;
        left: 0;
        right: 0;
        height: 190px;
        position: absolute;
        pointer-events: none;
        z-index: 1;
      }   
    }
    #search_block{
      position: relative;
      z-index: 2;
    }
    .style3-airplane {
      width: 170px;
      display: block;
      position: absolute;
      top: -40px;
      left:20px;
      @media screen and (min-width : 1500px){
        left: -10vw;
      }
      &+ .page-container{
        position: relative;
      }
    }
  }
  .home-top-slogan {
    text-align: right;
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 0 7px #000000ad;
    @media screen and (min-width : $medium){
      padding: 20px 0 0;
    }
    @media screen and (min-width : $large){
      padding: 50px 0 0;
      font-size: 52px;
    }
    .subslogan{
      font-size: 16px;
      margin-top: 15px;
      @media screen and (min-width : $large){
        margin-top: 30px;
      }
    }
  }
  .home-tab .svgIcon{
    display: block;
  }
  .home-tabs-holder a.home-tab{
    @media screen and (min-width : $medium){
      background-color: #eee;
    }
    color: #fff;
    border-radius: 0;
    padding: 12px 5px;
    @media screen and (min-width : $medium){
      border-left: 0 none;
      background-color: #ffffff42;
      border-radius: 4px 4px 0 0;
      padding-right:25px;
      padding-left:25px;
    }
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-weight: bold;
    svg{
      fill: currentColor;
      height: 20px;
      width: 20px;
    }
    &.home-active-tab{
      color: #555;
      background-color: #fff;
    }    
  }
  &.body .home-search-section .search-form-wrapper{
    background-color: transparent;
    padding: 0;
  }
  .search-box-holder label{
    color: inherit;
  }
  .add-destination-holder .form-group {
    margin: 0;
  }
  .search-form-inner-wrapper {
    background: #fff;
    border-radius: 5px 0 5px 5px;
    padding: 15px 5px;
    @media screen and (min-width : $medium){
      padding: 20px 20px 5px;
    }
  }
  .origin-destination-block {
    display: flex;
    flex-direction: column;
    gap: 15px;
    @media screen and (min-width : $medium){
      flex-direction: row;
      align-items: center;
    }
    .autocompleteText input.form-input{
      background-image: none;
      padding-left: 10px;
      padding-right: 10px;
      margin: 0;
      border: 1px solid #eee;
      border-radius: 4px;
      outline: none;     
    }
  }
  .triptype-radio-group {
    margin: 0;
    .triptype-radio-item {
      margin-left: 15px;
      flex-grow: 0;
      .triptype-radio-label{
        border-radius: 0;
        padding: 0;
        border-top: 0 none;
        border-right: 0 none;
        border-left: 0 none;
        box-shadow: none;
        font-size: 14px;
      }
      .triptype-radio:checked + .triptype-radio-label{
        background-color: transparent;
        border-bottom: 1px solid;
        color: $style3Yellow;
      }    
    }
  }
  
  .search-form-wrapper .date-block .rmdp-container .multi-datepicker-input{
    background-image: none;
    border: 1px solid #eee;
    height: 45px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
  }
  .styled-date-holder {
    padding: 0;
    border: 0 none !important;
    background: none !important;
    height: 40px;
    .multi-datepicker-input{
      opacity: 1;
    }
    .styled-date-inner-wrapper{
      display: none;
    }
  }
  .fake-return-date{
    display: none;
  }
  .search-route-item {
    display: block;
    @media screen and (min-width : $medium){
      display: flex;
    }
    .date-block {
      flex: auto;
      width: 100%;
      @media screen and (max-width : $medium_){
        margin-top: 15px;
      }
      &.departure-date{
        @media screen and (min-width : $medium){
          margin: 0;
        }
      }
    } 
  }

  .flight-search-form .search-route-item {
    .origin-destination-block {
      width: 100%;
      min-width: 66%;
      & > div{
        flex-grow: 1;
      }
    }  
  }
  .package-searchbox-holder .origin-destination-block{
    min-width: 50%;
  }

  .RoundTrip-searchbar .search-route-item .origin-destination-block {
    min-width: 50%;
  }
  
  .style3-type-radio {
    color: #333;
    .radio-mark{
      display: inline-block;
      padding: 2px;
      vertical-align: middle;
      border-radius: 50%;
      border: 1px solid $style3Yellow;
      width: 15px;
      height: 15px;
      &::before{
        content: "";
        display: block;
        height: 9px;
        width: 9px;
        border-radius: 50%;
      }
    }
    &.active .radio-mark::before{
      background-color: $style3Yellow;
    }
  }

  .select-passengers.large-select-passenger .selected-passengers-input{
    height: 45px;
    border: 1px solid #eee;
    margin-top: 0;
    border-radius: 4px;
  }
  .search-submit-col button {
    height: 45px;
    border-radius: 4px;
  }
  .remove-return-btn{
    display: none;
  }
  .triptype-radio-group{
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 15px;
    .triptype-radio-item{
      white-space: nowrap;
      padding: 0;
      margin-bottom: 0;
    }
  }
  .fixed-search-overlay{
    backdrop-filter: blur(2px);
  }
  .full-container {
    backdrop-filter: blur(2px);
    .search-box-holder{
      background-color: #fff;
    }
  
  }

  .button.yellow-btn{
    background-color: $style3Yellow;
    &:hover {
      background-color: $style3YellowHover;
    }
    &.pale{
      background-color: $style3YellowPale;
      &:hover {
        background-color: $style3YellowPaleHover;
      }
    }
  }
  .blue-bordered-message-block {
    color: $style3blue;
    border-color: $style3blue;
  }
  .box-border {
    border-radius: 5px;
  }

  .search-box-holder .hotel-destination-holder .form-input {
    background-image: none;
    outline: none;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0;
    border: 1px solid #eee;
    border-radius: 4px;
  }
  .hotel-search-form-holder .hotel-dates-group {
    .nights-column{
      @media screen and (min-width : $medium){
        border-radius: 4px;
      } 
    }
    .multi-datepicker-input,.nights-column {
      background-image: none;
      border: 1px solid #eee;
      height: 45px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .checkout-column{
      margin-right: 0;
      margin-left: 0;
      .multi-datepicker-input{
        border-right: 0 none;
        @media screen and (max-width : $medium_){
          border-left: 0 none;
        }
      }
      @media screen and (min-width : $medium){
        margin-left: 15px;
      }
    }
  }

  .fixed-search-holder{
    background-color: #fff;
  }

  .search-form-wrapper {
    .form-select.room-option-toggle-select {
      border: 1px solid #eee;
      outline: none;
    } 
    .passengers-options-holder-card{
      box-shadow: none;
      border-color: #eee;
    } 
  }

  .search-box-holder.package-searchbox-holder {
    .form-group .form-select.room-option-toggle-select{
      height: 45px;
    }  
    .submit-button {
      height: 45px;
      border-radius: 4px;
    } 
    @media screen and (min-width : $medium){
      .hotel-search-form-holder .button-holder{
        flex: 0 0 16.67%;
      }  
      .hotel-search-form-holder .hotel-search-form-main {
        flex: 0 0 83.33%;
      } 
    }
  }

  @media screen and (min-width : $medium){
    .foreign-hotel-search .hotel-search-form-holder {
      .button-holder{
        flex: 0 0 15%;
      }
      .hotel-search-form-main{
        flex: 0 0 85%;
      }      
    }
  }

  .domestic-hotel-details .search-box-holder{
    @media screen and (max-width : $medium_){
      padding-bottom: 15px;
    }
  }

  .item-p{
    @media screen and (min-width : $small){
      max-width: 160px;
    }
    margin: 0;
    flex-grow: 1;
  }
  
  .section-heading {
    font-weight: 600;
    text-align: center;
    font-size: 25px;
    color: #555;
    margin-bottom: 30px;
  }
  .destination-carousel-item {
    border-radius: 20px 20px 0 20px;
    display: block;
    overflow: hidden;
    border: 1px solid #ddd;
    color: #555;
    .image {
      height: 210px;
      object-fit: cover;
    }
    .yellow-left-carret{
      position: absolute;
      left: 10px;
      bottom: 12px;
    }
  }


  .slick-dots {
    li {
      margin: 0 2px;
      button:before{
        content: "";
        display: inline-block;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        border: 1px solid #ddd;
        background-color: #bbb;
        opacity: 1;
      }
      &.slick-active button:before{
        background-color: #fff;
      }
    }

  }

  .slick-dots{
    bottom: -30px;
  }

  .style3-slider-section {
    // .page-container{
    //   padding: 30px 0;
    //   @media screen and (min-width : $small){
    //     padding-bottom: 0;
    //   }
    // }
    padding: 50px 0;
    @media screen and (min-width : $medium){
      padding: 80px 0;
    }
    .slick-dots {
      @media screen and (min-width : $small){
        position: absolute;
        bottom: 35px;
        left: 35px;
        width: auto;
      }
    }
  }
  .footer{
    border: 0 none;
  }
  .three-large-icon-section {
    background-color: #f2f2f2;
    padding: 20px 0;
  }
  .three-large-icon-section-inner {
    .item-title{
      font-weight: 600;
      font-size: 15px;
      color: #58595b;
      @media screen and (min-width:$medium){
        font-size: 17px;
      }
    }
    .three-large-icon-item {
      width: 60px;
      margin-bottom: 10px;
    }
    .icon-item {
      padding: 10px;
    }
    @media (min-width: $medium){
      display: flex;
      align-items: start;
      justify-content: space-around;
      .three-large-icon-item {
        width: 100px;
        margin-bottom: 20px;
      }
      .item-title{
        font-weight: bold;
        font-size: 19px;
      }
      .icon-item {
        padding: 30px;
      }
    }
  }
  .style3-top-destinations-section{
    background-color: #fff;
    padding: 70px 0 60px;

    .slick-dots {
      li {
        button:before{
          border: 1px solid #bbb;
          background-color: #aaa;
          opacity: 1;
        }
        &.slick-active button:before{
          background-color: #fff;
        }
      }
  
    }

  }

  .yellow-left-carret{
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: $style3Yellow;
    clip-path: polygon(100% 0, 100% 100%, 50% 50%);
    align-self: flex-end;
    margin-bottom: 5px;
  }
  .style3-slider-item {
    padding: 0 10px;
  }
  .style3-slider-banner {
    height: 350px;
    object-fit: cover;
  }
  .style3-slider-item-inner {
    border: 1px solid #ccc;
    overflow: hidden;
    border-radius: 15px;
    .style3-slider-text {
      position: absolute;
      bottom: 50px;
      right: 0;
      padding: 15px;
      background: #fff;
      color: #555;
      display: flex;
      border-radius: 0 0 0 15px;
      .divider {
        background: $style3Yellow;
        width: 1px;
        margin: 0 15px;
      }    
      @media screen and (max-width : $small_){
        bottom: 0;
        left: 0;
        right: 0;
      }
    }    
  }

  .footer-namad-item {
    margin-left: 5px;
    margin-right: 5px;
    >span,>img{
      background: #f1f1f1;
      height: 77px;
      width: 77px;
      display: block;
      object-fit: contain;
      border-radius: 10px;
      margin: 0 auto 10px;
    }
  }
  .bottom-footer {
    padding: 0;
    background: transparent;
    hr{
      margin: 0;
    }
  }

  .style2footer-row{
    @media screen and (min-width:$medium){
      display: flex;
      .style2footer-col1,.style2footer-col2{
        flex: 0 0 20%;
      }
      .style2footer-col3{
        flex: 0 0 60%;
        align-self: center;
      }
    }
  }
  .namads-holder{
    padding: 50px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media screen and (min-width:$large){
      justify-content: space-between;
    }
  }
  .footer-namad-item{
      width: auto;
  }
  
.style-2-footer-bottom {
  padding: 25px 0;
  @media screen and (min-width:$medium){
    display: flex;
    justify-content: space-between;
  }

}
.namads-holder {
  & > a, & > span, & > img{
    padding: 0;
  }
}

.style-3-support-section {
  background-color: #f5f5f5;
  position: relative;
  @media screen and (max-width:$medium_){
    padding: 20px 0;
  }
  @media screen and (min-width:$medium){
    margin-bottom: 50px;
  }
}
.style-3-support-inner {
  color: #333;
  .phone-number strong{
    position: relative;
    top: 2px;
  }
  .email,.phone-number{
    margin: 15px 0;
    display: flex;
    align-items: center;
    gap: 5px;
    .icon{
      fill: $style3Yellow;
      width: 30px;
      height: 30px;
      @media screen and (min-width:$large){
        width: 45px;
        height: 45px;
        border: 2px solid$style3blue;
        border-radius: 50%;
        padding: 7px;
      }
      margin-left: 10px;
    }
  }
  strong{
    font-size: 18px;
    font-weight: bold;
    @media screen and (min-width:$medium){
      font-size: 17px;
    }
    @media screen and (min-width:$large){
      font-size: 20px;
    }
  }
  @media screen and (min-width:$medium){
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  label{
    font-size: 13px;
  }
  .style-3-support-icon {
    fill: $style3Yellow;
    width: 30px;
    margin-left: 10px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    @media screen and (min-width:$large){
      width: 60px;
      height: 60px;
      fill: #ffffff;
      margin-left: 15px;
    }
  }
  p{
    margin-bottom: 0;
    @media screen and (min-width:$large){
      font-size: 14px;
    }
  }
}

@media screen and (min-width:$medium){
  .style-3-support-heading {
    background: $style3Yellow;
    color: #fff;
    padding: 20px 0 20px 20px;
    position: relative;
    &::before{
      content: "";
      display: block;
      position: absolute;
      background-color: $style3Yellow;
      top: 0;
      left: 100%;
      height: 100%;
      width: 50vw;
    }
    &::after{
      content: "";
      display: block;
      position: absolute;
      background-color: $style3Yellow;
      top: 50%;
      transform: translateY(-50%);
      right: 100%;
      height: 50px;
      width: 13px;
      margin-right: -1px;
      clip-path: polygon(100% 0 , 0 50%,  100% 100%);
    }
  }
}

.safarmarket-footer-inner .footer-namad-item{
  border-radius: 12px;
}


.mobile-tab-hotel-flight{
  @media screen and (max-width:$medium_){
    background-color: #aaa;
  }
}

.flight-result-item,
.ticket-style-section{
  border-radius: 5px;
}
.medium-box-border{
  @media screen and (min-width:$medium){
    border-radius: 5px;
  }
}


.icon-hint-holder{
  color: $style3Yellow;
  svg{
    width: 28px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
  }
  .hint-inner{
    color: inherit;
    background-color: #cfa63026;
  }
}
.golden{
  color: $style3Yellow;
}

&.body .main-content .ant-collapse-header .passenger-type-title{
  color: $style3blue;
}
.link-style-btn,.content-link{
  color: $style3blue;
}
.has-fake-magnifirer-icon:before{
  background-color: $style3blue;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-position: 74.2% 0;
}
.ant-steps-item-process .ant-steps-item-icon{
  background-color: $style3Yellow;
  border-color: $style3Yellow;
}
.top-color-border.orange {
  border-top-color: $purple;
  .colored{
    color: $purple;
  }
}
.package.search-result-page .flightDetails .itours-tab-holder .itours-tab-list .itours-tab-btn{
  background-color: $style3blue;
  &:hover{
    background-color: $style3blueHover;
  }
  &.active{
    color: $style3blue;
  }
}
.selected-package{
  border-color: $style3blue;
}
.package.search-result-page .filters-holder .filter-header{
  background-color: $style3Yellow;
}

.logo-loading {
  width: 110px;
  height: 110px;
  &::after{
    background-image: none;
    border: 7px solid;
    border-color: transparent transparent transparent $style3Yellow;    
  }
  img{
    width: 90px;
    height: 90px;
    margin-top: 0;
    margin-left: 0;
    transform: translate(-50%,-50%);
  }
}

&.body select.results-sort-select{
  color: $style3Yellow;
  border-color: $style3Yellow;  
}
.Pagination .page-btn:not(.disabled){
  color: $style3blue;
  &:hover,&.clicked{
    background-color: $style3blue;
    color: #fff;
  }
}

.detail-flight .stop-info{
  background-color: #cfa63026;
}
.progressbar-wrapper{
  .bar-fill{
    background-color: $style3Yellow;
  }
}

.inline-block.flight-type-and-seats .ant-tag {
  border-color: #ff8e14;
  color: #ff8e14;
  background: transparent;
  font-weight: bold;
  font-size: 13px;
}
.flight-result-item{
  color: #111;
  .gray-a{
    color: #3a3a3a;
  }
}
.available-seats{
  white-space: nowrap;
}
@media screen and (max-width:$medium_) {
  .search-result-page .flight-result-item .included-baggage-mark{
    left: 50%;
    transform: translate(-50%);
    margin-left: 0;
    margin-right: 0;
  }
  div.flight-result-item .item-footer {
    display: flex;
    padding: 10px;
    align-items: flex-end;
    .price {
      color: #999999;
      font-weight: bold;
      font-size: 17px;
    }

    .price-and-book {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 5px;

      b {
        margin-left: 0;
        margin-right: 0;
      }

      .button {
        min-width: 120px;
      }
    }

    .flight-footer-top-footer {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 5px;

      .ant-tag {
        margin-right: 0;
      }

      .button {
        margin: 0;
      }
    }
  }
}

  &.body:not(.ltr) {
    .hotel-search-form-holder .button-holder{
      @media screen and (min-width : $medium){
        padding-right: 15px;
      }
    }
    .home-tabs-holder a.home-tab:not(:last-child){
      @media screen and (min-width : $medium){
        margin-left: 10px;
      }
      @media screen and (max-width : $medium_){
        border-left: 1px solid #fff;
      }
    }

    
    .autocompleteText .clear-autocomplete {
      right: 5px;
    }

    @media screen and (min-width : $medium){
      .search-route-item .origin-destination-block{
        margin-left: 15px;
      }
      // .origin-destination-block  {
      //   .flight-origin-holder .autocompleteText input.form-input{
      //     border-top-left-radius:0 ;
      //     border-bottom-left-radius:0 ;
      //     border-left: 0 none;
      //   }
      //   .flight-destination-holder .autocompleteText input.form-input{
      //     border-top-right-radius:0 ;
      //     border-bottom-right-radius:0 ;
      //   }
      // }
      // .search-bar.RoundTrip-searchbar,.package-searchbox-holder {
      //   .departure-date .multi-datepicker-input{
      //     border-top-left-radius:0 ;
      //     border-bottom-left-radius:0 ;
      //     border-left: 0 none;
      //   }
      //   .return-date .multi-datepicker-input{
      //     border-top-right-radius:0 ;
      //     border-bottom-right-radius:0 ;
      //   }        
      // }

      .checkout-column .multi-datepicker-input{
        border-top-left-radius:4px ;
        border-bottom-left-radius:4px ;
      }

    }

    .style3-top-header {
      .phone-number,.email-address {
        margin-top: 4px;
      }      
    }

    .style3-main-header-inner {
      &::after,&::before{
        display: none;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
      & > div {
        order: 2;
      }
      .main-menu-wrapper{
        order: 1;
        min-width: 87px;
      }
      @media screen and (min-width : $medium){
        gap: 35px;
        align-items: baseline;
        .main-menu-wrapper{
          flex: 1 0 100px;
          order: 2;
        }
      }
    }
    .style3-main-header {
      background: $style3blue;
      padding: 15px 0;
      .menu-toggle-btn .menu-btn-bar{
        background-color: #fff;
      }
      @media screen and (min-width : $medium){
        padding: 5px 0;
        .main-menu .menu-item {
          a{
            color: #fff;
            font-weight: bold;
          }
          &.active a{
            color: $style3Yellow;
            border-bottom: 1px solid $style3Yellow;
          }
        }
      }
    }
    button.track-order-btn {
      line-height: 11px;
      background: transparent;
      color: #fff;
      border: 1px solid $style3Yellow;
      font-size: 14px;
    }

  }
  &.body.ltr {
    .home-tabs-holder a.home-tab:not(:last-child){
      @media screen and (min-width : $medium){
        margin-right: 10px;
      }
      @media screen and (max-width : $medium_){
        border-right: 1px solid #fff;
      }
    }

    .autocompleteText .clear-autocomplete {
      left: 5px;
    }
  }

  .airline-table-holder{
    border: 0 none;
    padding-bottom: 5px;
    .airline-table-header {
      margin-top: 13px;
    }
    .airline-logo {
      width: 50px;
      height: 50px;
      background: #fff;
      box-shadow: 0px 0px 5px #0003;
      border-radius: 50%;
      object-fit: contain;
      padding: 2px;
      margin: 7px auto;
    }    
  }
  .airline-filter-item{
    margin: 0 3px;
    border: 0 none;
    position: relative;
    &:not(:last-child)::after{
      content: "";
      display: block;
      position: absolute;
      height: 40px;
      width: 1px;
      background-color: $style3Yellow;
      top: 10px;
      left: -3px;
    }
  }
  .airline-filter-wrapper {
    margin-top: 10px;
    background: #fff;
    padding: 5px 10px 10px;
    border-radius: 5px;
    border: 1px solid $style3Yellow;
    .ant-checkbox .ant-checkbox-inner{
      background-color: #fff;
      border-radius: 4px;
    }
    .cell {
      background: #c8c8c8;
      font-weight: 600;
      border-radius: 5px;
      & + .cell {
       background : #e6e6e6;
       margin-top: 5px;
      }
    }

    .airline-table-header .airline {
      height: 64px;
    }

  }
  .detail-flight img.airline,
  .summery-airline-logo img.airline-logo{
    width: 50px;
    height: 50px;
    background: #fff;
    box-shadow: 0px 0px 5px #0003;
    border-radius: 50%;
    object-fit: contain;
    padding: 2px;
  }

  .search-route-item .origin-destination-block .swap-vert{
    @media screen and (min-width:$medium){
      right: 50%;
      margin-right: -13px;
      transform: rotate(90deg);
    }
    @media screen and (max-width:$medium_){
      right: auto;
      left: 20px;
    }
  }

}

.safarmarket-footer-inner .footer-namad-item {
  border-radius: 12px;
}