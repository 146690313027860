@import "base";
.itours-icon {
  background-image: url("../image/icons.svg");
  aspect-ratio: 1;
  
  //as IE dosnt support svg background:
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background-image: url("../image/icons.png");
  }
  background-size: 3200% 600%;
  width: 26px;
  height: 26px;
  display: inline-block;
  vertical-align: middle;
  &.icon-small {
    width: 17px;
    height: 17px;
  }
  &.icon-xsmall {
    width: 12px;
    height: 12px;
  }
  &.icon-small2 {
    width: 20px;
    height: 20px;
  }
  &.icon-medium {
    width: 30px;
    height: 30px;
  }
  &.icon-large {
    width: 40px;
    height: 40px;
  }
  &.icon-xlarge {
    width: 70px;
    height: 70px;
  }
  &.icon-xxlarge {
    width: 100px;
    height: 100px;
  }

  &.top-plane-white {
    background-position: 0 0;
  }
  &.hotel-white {
    background-position: 3.2% 0;
  }
  &.package-white {
    background-position: 6.4% 0;
  }
  &.check-badge{
    background-position: 9.7% 0;
  }
  &.headset-support{
    background-position: 12.9% 0;
  }
  &.headset-support-gray{
    background-position: 16.1% 0;
  }
  &.style-2-flight-red {
    background-position: 19.3% 0;
  }
  &.style-2-hotel-red {
    background-position: 22.6% 0;
  }
  &.style-2-package-red {
    background-position: 25.8% 0;
  }  
  &.style-2-flight-line-white {
    background-position: 29% 0;
  }
  &.style-2-hotel-line-white {
    background-position: 32.3% 0;
  }
  &.style-2-package-line-white {
    background-position: 35.5% 0;
  }
  &.red-clock-icon {
    background-position: 16.1% 40%;
  }
  &.moon-dark {
    background-position: 32.4% -20%;
  }
  &.style-2-domestic-flight-line-white {
    background-position: 38.7% 0;
  }
  &.flight2-white {
    background-position: 45.1% 0;
  }
  &.package2-white {
    background-position: 48.4% 0;
  }
  &.hotel2-white {
    background-position: 51.6% 0;
  }
  &.checkmark-a {
    background-position: 54.8% 0;
  }
  &.flight2-red {
    background-position: 58.1% 0;
  }
  &.package2-red {
    background-position: 61.3% 0;
  }  
  &.hotel2-red {
    background-position: 64.5% 0;
  }  
  &.close-icon {
    background-position: 67.78% 0;
  }
  &.white-close-icon {
    background-position: 67.78% 0;
  }
  &.dark-grey-cup {
    background-position: 80.6% 0;
  }
  &.dark-grey-food {
    background-position: 83.9% 0;
  }
  &.dark-grey-spoon {
    background-position: 87.1% 0;
  }
  &.peyment-error {
    background-position: 87.1% 0;
  }
  &.peyment-warning {
    background-position: 90.3% 0;
  }
  &.peyment-exchange {
    background-position: 93.5% 0;
  }
  &.peyment-ok {
    background-position: 96.8% 0;
  }
  &.peyment-ok-yellow {
    background-position: 71% 0;
  }
  &.whatsapp {
    background-position: 100% 0;
  }


  &.top-plane-gray {
    background-position: 0 20%;
  }
  &.hotel-gray {
    background-position: 3.2% 20%;
  }
  &.package-gray {
    background-position: 6.4% 20%;
  }
  &.star {
    background-position: 9.7% 20%;
  }
  &.user-circle-dark {
    background-position: 12.9% 20%;
  }
  &.gray-clock-icon {
    background-position: 16.1% 20%;
  }
  &.green-baggage {
    background-position: 22.62% 20%;
  }
  &.red-no-baggage {
    background-position: 25.8% 20%;
  }
  &.style-2-flight-line-blue {
    background-position: 29% 20%;
  }
  &.style-2-hotel-line-blue {
    background-position: 32.3% 20%;
  }
  &.style-2-package-line-blue {
    background-position: 35.5% 20%;
  }  
  &.style-2-domestic-flight-line-blue {
    background-position: 38.7% 20%;
  }
  &.moon-white {
    background-position: 41.9% 20%;
  }
  &.wallet {
    background-position: 45.1% 20%;
  }
  &.exit {
    background-position: 48.4% 20%;
  }
  &.profile {
    background-position: 51.6% 20%;
  }
  &.checkmark-b {
    background-position: 54.8% 20%;
  }
  &.style-2-hotel-line-gray{
    background-position: 58.1% 20%;
  }
  &.gray-close-icon {
    background-position: 67.78% 20%;
  }
  &.error-3 {
    background-position: 71% 20%;
  }
  &.check-blue {
    background-position: 74.2% 20%;
  }
  &.check-yellow {
    background-position: 77.4% 20%;
  }
  &.plus-blue-icon {
    background-position: 80.6% 20%;
  }
  &.trash {
    background-position: 83.9% 20%;
  }
  &.blue-info {
    background-position: 87.1% 20%;
  }
  &.instagram {
    background-position: 90.3% 20%;
  }
  &.telegram {
    background-position: 93.5% 20%;
  }
  &.twitter {
    background-position: 96.8% 20%;
  }
  &.yellow-warning {
    background-position: 100% 20%;
  }


  &.red-clock-icon {
    background-position: 16.1% 40%;
  }
  &.red-email-icon {
    background-position: 22.62% 40%;
  }
  &.red-address-icon {
    background-position: 25.8% 40%;
  }
  &.red-phon-icon {
    background-position: 32.3% 40%;
  }
  &.error2-icon {
    background-position: 38.7% 40%;
  }
  &.error-icon {
    background-position: 41.9% 40%;
  }
  &.red-close{
    background-position: 67.78% 40%;
  } 
  &.red-arrow-right {
    background-position: 74.2% 40%;
  }
  &.plus-icon {
    background-position: 93.5% 40%;
  }
  &.minus-icon {
    background-position: 90.3% 40%;
  }


  &.top-plane-blue {
    background-position: 0 60%;
  }
  &.hotel-blue {
    background-position: 3.2% 60%;
  }
  &.package-blue {
    background-position: 6.4% 60%;
  }
  &.user-profile {
    background-position: 51.6% 60%;
  }
  &.change-password {
    background-position: 54.8% 60%;
  }
  &.flight-ticket {
    background-position: 87.1% 60%;
  }
  &.circle-error {
    background-position: 90.3% 60%;
  }
  &.check-green {
    background-position: 93.5% 60%;
  }
  &.info-icon {
    background-position: 96.7% 60%;
  }
  &.hotel-ticket {
    background-position: 100% 60%;
  }


  &.plus-simple-icon {
    background-position: 90.3% 80%;
  }
  &.green-info-icon {
    background-position: 96.7% 80%;
  }
  

  &.adult {
    background-position: 0 100%;
  }
  &.child {
    background-position: 3.2% 100%;
  }
  &.infant {
    background-position: 6.4% 100%;
  }
  &.guest-dark {
    background-position: 9.7% 100%;
  }
  &.guests-dark {
    background-position: 12.9% 100%;
  }
  &.white-clock-icon {
    background-position: 16.1% 100%;
  }
  &.checkin-dark {
    background-position: 19.3% 100%;
  }
  &.checkout-dark {
    background-position: 22.6% 100%;
  }
  &.location-dark {
    background-position: 25.8% 100%;
  }
  &.hotel-dark {
    background-position: 29% 100%;
  }
  &.moon-dark {
    background-position: 32.4% 100%;
  }
  &.calendar-dark {
    background-position: 35.5% 100%;
  }
  &.empty-moon {
    background-position: 38.7% 100%;
  }
  &.fill-moon {
    background-position: 41.9% 100%;
  }
  &.empty-sun {
    background-position: 45.1% 100%;
  }
  &.fill-sun {
    background-position: 48.4% 100%;
  }
  &.contact-us {
    background-position: 51.6% 100%;
  }
  &.terms {
    background-position: 54.8% 100%;
  }  
  &.about-us {
    background-position: 58.1% 100%;
  }
  &.breakfast-icon {
    background-position: 61.3% 100%;
  }  
  &.green-checkmark {
    background-position: 64.5% 100%;
  }
  &.facebook-gray{
    background-position: 67.78% 100%;
  }  
  &.right-arrow-dark {
    background-position: 74.2% 100%;
  }
  &.search-icon {
    background-position: 77.4% 100%;
  }
  &.user-login {
    background-position: 80.6% 100%;
  }
  &.facebook {
    background-position: 83.9% 100%;
  }
  &.baggage-circle {
    background-position: 87.1% 100%;
  }
  &.fork-knife-circle {
    background-position: 90.3% 100%;
  }
  &.check-white {
    background-position: 93.5% 100%;
  }
  &.red-info-icon {
    background-position: 96.7% 100%;
  }
  &.help-icon{
    background-position: 100% 100%;
  }
}
