@import "base";

//new style:
.hotel-gallery{
  & > div {
    height: 350px;
    @media screen and (max-width: $medium_){
      height: 200px;
    }        
  }
  .image {
    cursor: pointer;
    height: 100%;
    background-size: cover;
    background-position: center;    
    @media screen and (min-width: $medium){
      border: 1px solid #fff;
    }       
    position: relative;
    .hover {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      cursor: pointer;
    }
  }
}
.gallery-row {
  display: flex;
  & > div {
    flex: 1 1;
    &.large-item{
      flex: 4 4;
    }
  }
}
.gallery-column {
  @media screen and (max-width: $medium_){
    display: none;       
  }    
  display: flex;
  flex-direction: column;
  & > div {
    flex: 1 1;
  }
}
.more-images-text{
  display: none;
  color: #fff; 
}
.gallery-column:last-child > div:last-child {
  .hover{
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;    
    .more-images-text{
      display: block;
    }
  }
}
.react-images__dialog{
  .react-images__navigation button,button.react-images__header_button{
    background: transparent;
    border: 0 none;
    outline: none;
  }
}
@media (hover:hover){
  .hotel-gallery:not(.one-photo-gallery) .image{
    cursor: pointer;
    transition: all .15s linear;
    background-size: 100%;
    &:hover{
      background-size: 110%;
    }
  }
}