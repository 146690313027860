@import "base";

.Pagination{
    text-align: center;
    .page-btn {
      background: #fff;
      padding: 0 10px;
      border: 1px solid #ccc;
      border-width: 1px 1px 1px 0;
      display: inline-block;
      height: 27px;
      line-height: 27px;
      outline: none;
      cursor: pointer;
      vertical-align: middle;
      font-family: iransans;      
      min-width: 0;
      color: $blueLink;
      font-weight: 600;
      &.clicked{
        color: #000;
        background: #fff;
        cursor: default;
        &:hover,&:focus{
          color: #000;
          background: #fff;
        }
      }
      &.disabled{
        color: #bbb;
        background: #fff;
        cursor: no-drop;
        &:hover,&:focus{
          color: #bbb;
          background: #fff;
        }        
      }
      &:hover{
        background: $blueLink;
        color: #fff;
      }
    }
    .prev-btn{
      border-radius: 0 3px 3px 0;
      font-weight: 600;      
      &::before{
        content: "";
        display: inline-block;
        width:5px;
        height:5px;
        border: 1px solid;
        border-width: 2px 2px 0 0 ;
        vertical-align: middle;
        margin-left: 5px;
        @include transform(rotate(45deg));
      }
    }
    .next-btn{
      border-radius: 3px 0 0 3px;
      border-width: 1px;
      font-weight: 600;
      &::after{
        content: "";
        display: inline-block;
        width:5px;
        height:5px;
        border: 1px solid;
        border-width: 0 0 2px 2px ;
        vertical-align: middle;
        margin-right: 5px;
        @include transform(rotate(45deg));
      }      
    }

}


/* 
.PageContainer{
  max-width: $large_;
  margin: 10px auto;


  input{
    border:0;
    padding: 5px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 2px;
    outline: none;
    cursor: pointer;
    font-family: $font;
    font-weight: 600;
    background-color: $yellowText;
    transition: all 0.2s ease-out;
    &.clicked{
      background-color: $orangeText;
      color:white;
    }
    &:hover{
      background-color: $baseColor;
      color:white;
    }
  }
} */