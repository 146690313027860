@import "base";

.search-airline-block .autocompleteText .clear-autocomplete{
  body:not(.ltr) & {
    right: 10px;
  }
}


.autocompleteText {
  input.form-input[value=" "] + .clear-autocomplete {
    display: none;
  }
  .clear-autocomplete {
    position: absolute;
    top: 9px;

    body:not(.ltr) & {
      right: 35px;
    }
    body.ltr & {
      right: 10px;
    }
    .autocompleteFeild.right-to-left-autocomplete & {
      left: 10px;
      right: auto;
    }
    .detail-search-holder & {
      .body.ltr & {
        right: 10px;
        left: auto;
      }
    }
    cursor: pointer;
    background-color: #fff;

    .detail-search-holder & {
      top: 5px;
      body:not(.ltr) & {
        right: 5px;
      }
      body.ltr & {
        left: 5px;
      }
    }
  }
  position: relative;
  .loaderText {
    position: absolute;
    top: 3px;
    body:not(.ltr) & {
      left: 6px;
    }
    body.ltr & {
      right: 6px;
    }
    font-size: 10px;
    margin: 0 auto;
    width: 3.2em;
    height: 3.2em;
    border-radius: 50%;
    background: linear-gradient(to right, #ffffff 10%, #80ceea 42%);
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .loaderText:before {
    width: 50%;
    height: 50%;
    background: white;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }
  .loaderText:after {
    background: white;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  input {
    .detail-search-holder & {
      transition: all 0.2s linear;
    }
    .hotel-destination-holder & {
      background-image: url("../image/location-icon.svg");
    }
    .flight-origin-holder & {
      background-image: url("../image/target-icon.svg");
    }
    .flight-destination-holder & {
      background-image: url("../image/location-icon.svg");
    }
    background-repeat: no-repeat;
    background-size: 20px;

    body:not(.ltr) & {
      background-position: top 12px right 5px;
      padding-right: 35px;
    }
    body.ltr & {
      background-position: top 12px left 5px;
      padding-left: 35px;
    }
    .body .airline-autocomplete-holder & {
      padding: 6px 10px;
    }
  }
  .suggestList {
    &::before {
      position: absolute;
      z-index: -1;
      content: "";
      body:not(.ltr) & {
        right: 15px;
      }
      body.ltr & {
        left: 15px;
      }
      top: -5px;
      width: 11px;
      height: 11px;
      border-top: 1px solid #aaa;
      border-left: 1px solid #aaa;
      @include transform(rotate(45deg) skew(10deg, 10deg));
      background: #fff;
    }
    direction: ltr;
    &.rtl{
      direction: rtl;
      ul li .link {
        padding-right: 36px;
        padding-left: 0;
        img {
          right: 10px;
          left: auto;
        }        
        .name {
          text-align: right;
        }
        .location {
          text-align: right;
        }
      }
    }
    position: absolute;
    background-color: white;
    box-shadow: 0 0 4px grey;
    min-width: 300px;
    width: 100%;
    top: 47px;
    border-radius: 3px;
    z-index: 11;
    -webkit-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    span.icon {
      position: relative;
      top: 3px;
      margin-right: 5px;
    }
    @media screen and (max-width: $medium_) {
      min-width: 100%;
    }
    & > ul {
      max-height: 310px;
    }
    ul {
      width: 100%;
      color: $text;
      margin: 0;
      line-height: 24px;
      list-style: none;
      padding: 0;
      overflow-y: auto;
      li {
        cursor: pointer;
        transition: all 0.4s ease-out;
        .link {
          position: relative;
          margin: 0;
          padding: 8px 6px;
          padding-left: 36px;
          .name {
            width: 100%;
            display: inline-block;
            text-align: left;
            &.important{
              font-size: 16px;
              padding-top: 10px;
              padding-bottom: 10px;
            }
          }
          img {
            position: absolute;
            max-width: 18px;
            left: 10px;
            top: 12px;
            &.important.icon{
              top: 17px;
            }
          }
          .autocomplete-list-icon {
            position: absolute;
            width: 20px;
            height: 20px;
            left: 10px;
            top: 8px;
            fill: currentColor;
            &.important.icon{
              top: 17px;
            }
          }
          &:hover {
            background-color: #d3dfe6;
            //color: #fff;
          }
          .location {
            text-align: left;
            font-size: 12px;
          }
        }
        .parentLink {
          ul li {
            padding: 8px 8px;
            padding-left: 16px;
            text-align: left;
            .name {
              padding-left: 4px;
            }
            &:hover {
              background-color: #d3dfe6;
            }
          }
        }
      }
    }
  }
}
.body .main-content .form-group .input-is-fa.autocompleteFeild{
  .loading-circle.small,.clear-autocomplete {
    right: auto;
    left: 5px;
  }
  .autocompleteText .suggestList {
    direction: rtl;
    ul li .link{
      font-family: inherit;
      padding-left: 6px;
      padding-right: 36px;
      .name,.location {
        text-align: right;
      }
      img{
        right: 10px;
        left: auto;
      }
    } 
  } 
}
.form-input.en-input {
  text-align: left;
  direction: ltr;
}
