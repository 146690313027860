@import "./base";

$sunhotelRed: #b52555;
$sunhotelRedHover: #a92350;

.body.sunhotel-theme {
  &,
  .ticket-style-section:first-child::before,
  .ticket-style-section:first-child::after {
    background-color: #fff;
  }
  .cities-section,
  .newletter-section {
    background-color: #f1f1f1;
  }
  .bottom-footer {
    background-color: rgb(17, 50, 80);
    color: #fff;
  }
  .main-menu .menu-item.active a {
    color: $sunhotelRed;
    border-color: $sunhotelRed;
  }
  .button.red-btn {
    background-color: $sunhotelRed;
    &:hover {
      background-color: $sunhotelRedHover;
    }
  }
  .color-1-btn:not(:disabled) {
    background-color: $yellow;
    color: #fff;
    border-color: $yellow;
    &:active {
      background-color: $yellow;
    }
  }
  //   .home-search-section .search-form-wrapper {
  //     background-color: rgba(255, 255, 255, 0.15);
  //   }
  .purple {
    color: $sunhotelRed;
  }

  .home-search-section {
    position: relative;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.2);
      @media (max-width: $medium_){
        display: none;
      }
    }
    .home-top-slogan {
      text-shadow: 0 0 5px black;
    }
    & > div {
      position: relative;
    }
    @media (min-width: $medium) {
      .search-form-wrapper {
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0.9)
        );
      }
      .home-tabs-holder .home-tab {
        color: #333;
        background: linear-gradient(
          0,
          rgba(255, 255, 255, 0.65),
          rgba(255, 255, 255, 0.75),
          rgba(255, 255, 255, 0.9)
        );
        &.home-active-tab {
          background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.9),
            rgb(255, 255, 255)
          );
          color: $sunhotelRed;
        }
      }      
    }
    .validator-label {
      background-color: $sunhotelRed;
      &::before {
        border-color: $sunhotelRed transparent transparent transparent;
      }
    }
    .remove-return-btn {
      background-color: $yellow;
    }
  }

  .Home {
    .search-form-wrapper .search-box-holder label,
    .add-destination-holder .link-style-btn {
      color: #000;
    }
    .search-form-wrapper .search-box-holder .triptype-radio-label {
      color: #444;
    }
    .triptype-radio-item .triptype-radio:checked + .triptype-radio-label {
      background-color: rgba(255, 255, 255, 0.8);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }
  }
  .color-1 {
    color: $yellow;
  }
  .blue-border-right {
    border-right-color: $yellow;
  }
  .radio-item {
    .radio-label .radio-mark {
      border-color: $yellow;
    }
    .custom-radio:checked + .radio-label .radio-mark:before {
      background-color: $yellow;
    }
  }
  .has-fake-magnifirer-icon:before {
    background-color: $yellow;
  }
  .PDatePicker-holder > div .calendarContainer .dayWrapper.today button,
  .PDatePicker-holder
    > div
    .calendarContainer
    .dayWrapper.today
    button[disabled],
  .detail-flight .stop-info.change-airport,
  .tab-holder .tab-s.active-tab-s {
    background-color: $sunhotelRed;
  }

  .main-content .ant-collapse-header .passenger-type-title {
    color: $yellow;
  }
  .Pagination .page-btn:not(.disabled) {
    color: #bbb;
    &:hover {
      color: #fff;
      background-color: $yellow;
    }
    &.clicked,
    &.clicked:hover {
      color: $yellow;
      background-color: #fff;
      background-color: transparent;
    }
  }
  // .mobile-tab-hotel-flight .home-tab .itours-icon {
  //   display: none;
  // }
}
