@import "./base";

@font-face {
  font-family: "shabnam";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local("shabnam"), url("../Fonts/shabnam/Shabnam.woff2") format("woff2"); /* Chrome 26+, Opera 23+, Firefox 39+ */
}
@font-face {
  font-family: "shabnam";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("shabnam"),
    url("../Fonts/shabnam/Shabnam-Medium.woff2") format("woff2"); /* Chrome 26+, Opera 23+, Firefox 39+ */
}
@font-face {
  font-family: "shabnam";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: local("shabnam"), url("../Fonts/shabnam/Shabnam-Bold.woff2") format("woff2"); /* Chrome 26+, Opera 23+, Firefox 39+ */
}


body.style-2:not(.ltr) {
  .search-route-item .origin-destination-block {
    margin-left: 10px;
  }
  @media screen and (min-width : $medium){
    .hotel-search-form-holder .button-holder {
      padding-right: 10px;
    }  
  }
}  
body.style-2.ltr {
  .search-route-item .origin-destination-block {
    margin-right: 10px;
  }
  @media screen and (min-width : $medium){
    .hotel-search-form-holder .button-holder {
      padding-left: 10px;
    }  
  }  
}

.loading-icon-style2{
  width: 120px;
  margin-left: 10px;
}

@-webkit-keyframes dotsAnimation {
from {
  -webkit-transform: scale(1.1);
}

to {
  -webkit-transform: scale(.4);
}
}
@keyframes dotsAnimation {
from {
  transform: scale(1.1);
}

to {
  transform: scale(.4);
}
}
.loading3dot{
margin-top: 10px;
margin-bottom: 5px;
& > span{
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #08d4db;
  border-radius: 50%;
  margin: 10px;
  -webkit-animation: dotsAnimation .6s infinite ease-in-out alternate;
  animation: dotsAnimation .6s infinite ease-in-out alternate;
  &:first-child{
    animation-delay: 0;
  }
  &:nth-child(2){
    animation-delay: .3s;
  }
  &:nth-child(3){
    animation-delay: .6s;
  }
}
}

.style-2 {
  
  // &.body{
  //   font-family: shabnam, sans-serif;
  // }
  
  .float-row.half-gap {
    margin-left: -5px;
    margin-right: -5px;
    [class*="col-"] {
      padding-right: 5px;
      padding-left: 5px;
    }
  }

  .yellow{
    color: #febb02;
  }
  .Home{
    background: transparent;
  }
  &.body{
    background: #f6f7f9;
  }
  .button,.button[type=submit]{
    border-radius: 8px;
  }
  .link {
    cursor: pointer;
    transition: all 0.1s linear;
    color: #2196f3;
    &:hover,&:focus{
      color:#86c5f9 ;
    }
  }
  .autocompleteText .suggestList {
    min-width: 275px;
    ul li .link {
      color: inherit;
      &.blue{
        color: #2196f3;
        background: #e6f7ff94;
      }
      padding-top: 7px;
      padding-bottom: 7px;
      border-bottom: 1px solid #e8e8e8;
      line-height: 21px;
      font-family: 'opensans';
      font-size: 14px;
      &:hover,&:focus{
        background-color: #efefef;
      }
      img {
        filter: grayscale(1) brightness(1.5);
        top: 10px;
      }
    }
  }
  .home-top-slogan{
    text-shadow: 0 0 7px #ffffff9e;
    font-weight: bold;
    position: relative;
    font-size: 40px;
    padding-top: 0;
  }
  .style-2-search-section {
    z-index: 1;
  }
  .form-input,.form-select{
    outline: none;
  }
  .mobile-tab-hotel-flight{
    margin-bottom: 1rem;
  }
  .autocompleteText .suggestList{
    top: 100%;
  }
  .mobile-tab-hotel-flight .home-tabs-holder .home-tab{
    background-color: transparent;
    font-weight: 600;
    border-radius: 20px;
    .itours-icon {
      width: 22px;
      height: 22px;
    }
    @media screen and (max-width : $medium_ ){
      justify-content: center;
      align-items: center;
      display: flex;
    }
    @media screen and (min-width : $medium){
      border-radius: 0 0 15px 15px;
      padding: 15px 15px 10px;
      font-size: 15px;
    }
    color: inherit;
    &.home-active-tab{
      color: #034fa6;
      background-color: #fff;
      @media screen and (max-width:$medium_){
        border: 1px solid #bbb;
      }
    }
    &:not(:first-child){
      @media screen and (min-width : $medium){
        margin: 0 20px;
      }
    }
  }
  .style-2-header {
    background: #fff;
    border-bottom: 2px solid #bbb;
  }
  .search-form-wrapper .search-box-holder .form-group .class-select,
  .select-passengers .selected-passengers-input{
    height: 40px;
    border-radius: 10px;
  }

  @media screen and (min-width : $medium) {
    .layout-holder.is-home{
      .main-content{
        background-image: url('../image/banner-ir.jpg');
      }
      &.is-flight .main-content{
        background-image: url('../image/banner-ir.jpg');
      }
      &.is-foreign-flight .main-content{
        background-image: url('../image/banner-ir.jpg');
      }
      &.is-domestic-hotel .main-content{
        background-image: url('../image/banner-ir.jpg');
      }
      &.is-hotel .main-content{
        background-image: url('../image/banner-ir.jpg');
      }
      &.is-package .main-content{
        background-image: url('../image/banner-ir.jpg');
      }
    }
    .layout-holder.is-home{    
      .main-content{
        background-size: 100%;
        background-position: center top;
        &::before{
          content: '';
          display: block;
          position: absolute;
          background: linear-gradient(90deg, transparent, #ffffff52,transparent);
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
        }
      } 
      .style-2-header {
        background-color: transparent;
        box-shadow: none;
        border: 0 none;
      }
      .search-form-wrapper .class-select{      
        border-radius: 10px 10px 0 0;
      }
      .selected-passengers-input{
        border-radius: 0  0 10px 10px;
        @media screen and (min-width : $medium){
          height: 39px;
        }
      }
      .select-passengers.large-select-passenger .selected-passengers-input{
        @media screen and (min-width : $medium){
          height: 80px;
        }
      }
    }
  }
  &.body .search-box-holder{
    .autocompleteText input,
    .multi-datepicker-input,
    .nights-column {
      border-radius: 10px;
      background-image: none;
      padding-right:15px;
      padding-left:15px;
      height: 40px;
    }
  }
  &.body .is-home .search-box-holder{
    .autocompleteText input,
    .multi-datepicker-input,
    .nights-column {
      @media screen and (min-width : $medium){
        height: 80px;
      }
    }
  }
  .nights-column{
    line-height: 40px;
  }
  .is-home .nights-column{
    @media screen and (min-width : $medium){
      line-height: 80px;
    }
  }
  
  &.body:not(.ltr) {
    .search-result-page .select-class-block{
      @media screen and (min-width : $small) {
        margin-left: 10px;
      }
    }
    .hotel-dates-group {

      .checkout-column .PDatePicker-input,
      .checkout-column .ant-calendar-picker-input, 
      .checkout-column .multi-datepicker-input,
      .checkin-column .multi-datepicker-input,
      .nights-column{
        border-radius: 10px;
      }
    }
    .search-form-wrapper .departure-date:not(.one-way):not(.multy-destination) {
      @media screen and (min-width : $medium){
        margin-left:10px;
      }
    }
    .search-form-wrapper {
      .form-group .room-option-toggle-select{
        height: 40px;
        border-radius: 10px;
        @media screen and (min-width : $medium){
          height: 80px;
        }
      }
      .flight-origin-holder .form-input {
        border-radius: 10px;
        margin-top: 0;
      }
      .flight-destination-holder .form-input {
        border-radius: 10px;
        margin-top: 0;
      }
    }
    
    .autocompleteText .clear-autocomplete {
      right: 5px;
    }
    .inline-radio .radio-item {
      margin-left: 10px;
    }
    .inline-radio .radio-item:last-child {
      margin-left: 0;
    }
  }
  &.body.ltr {
    .hotel-dates-group {
      .checkin-column .multi-datepicker-input {
        border-radius: 10px 0 0 10px;
      }
      .nights-column{
        border-radius: 0 10px 10px 0;
      }
    }
    .search-form-wrapper .departure-date:not(.one-way):not(.multy-destination){
      margin-right: 10px;
    }
    .search-form-wrapper {
      .flight-origin-holder .form-input {
        border-radius: 10px 0 0 10px;
      }
      .flight-destination-holder .form-input {
        border-radius: 0 10px 10px 0;
      }
    }
    .autocompleteText .clear-autocomplete {
      left: 5px;
    }
    .inline-radio .radio-item {
      margin-right: 10px;
    }
    .inline-radio .radio-item:last-child {
      margin-right: 0;
    }
  }

  .style2-destinations {
    padding: 60px 0;
  }
  @media (min-width: $medium){
    .style-2-adv-slider-holder, .style2-destinations-section, .three-large-icon-section {
      background: #f6f7f9;
    }
  }
  .style-2-search-section{
    @media (min-width: $medium){
      min-height: calc(100vh - 95px);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      @media (min-height: 800px) {
        justify-content: center;
        .home-top-slogan {
          margin-bottom: 20px;
        }
      }
      // padding: 50px 0;
    }
    .search-box-holder{
      @media (max-width: $medium_){
        padding: 10px 0;
      }
      @media screen and (min-width : $medium){
        background-color: #00000091;
        border-radius: 20px;
        padding: 0 50px 25px;
        margin: 0 auto 35px;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        min-height: 200px;
        .home-tab{
          color: #fff;
        }
      }
      .hotel-destination-holder .form-input{
        border-radius: 10px;
      }
      max-width: 100%;
    }
  }
  .search-form-wrapper .hotel-destination-holder .form-input{
    border-radius: 10px;
  }
  .search-box-holder .triptype-radio-item .triptype-radio-label{
    border-radius: 10px;
    border: 1px solid #ddd;
    color: #555;
    box-shadow: none;
    padding: 5px;
    @media screen and (min-width : $small){
      padding: 5px 10px;
      border: 1px solid #fff;
      color: #fff;
    }
  }
  .autocompleteText {
    .loading-circle.small {
      height: 30px;
      top: 5px;
      @media screen and (min-width : $medium) {
        top: 25px;
      }
      &::before,&::after{
        height: 20px;
        width: 20px;
        border-width: 2px;
      }
    }
    .clear-autocomplete {
      top: 7px;
      @media screen and (min-width : $medium) {
        top: 25px;
      }
    }
  }
  .main-content .triptype-radio {
    font-size: 13px;
    background: #fff;
    padding: 1px 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #ccc;
    @media screen and (min-width: $small) {
      display: inline-block;
    }
    .radio-mark {
      display: none;
    }
    .radio-label {
      padding: 2px 7px;
      transition: all 0.1s linear;
      border-radius: 5px;
    }
    .custom-radio:checked + .radio-label{
      background-color: $green;
      color: #fff;
    }
  }
  .tracking-order-holder button.button{
    background-color: $yellow;
    border-radius: 0;
    height: 34px;
    top: 0;
  }

  @media (max-width: $medium_){
    .mobile-tab-hotel-flight {
        background: transparent;
    }
  }
  .safarmarket-hotel-search-btn{
    border-radius: 0;
    height: 35px;
  }
  .thickSearchBtn,.hotel-search-btn:not(.safarmarket-hotel-search-btn)  {
    height: 40px;
    border-radius: 10px;
    @media screen and (min-width : $medium){
      height: 80px;
    }
    .itours-icon{
      display: none;
    }
  }

  .origin-destination-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      flex: auto;
    }
    .flight-origin-holder{
      margin-left: 10px;
    }
    .swap-style2 {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      margin-top: -12px;
      right: 75px;
      cursor: pointer;
      right: 50%;
      margin-right: -13px;
    }
  }
  .select-passengers.large-select-passenger .selected-passengers-input{
    margin-top: 0;
    border-radius: 10px;
  }
  .select-passengers  .select-passenegers-block {
    border-radius: 10px;
    box-shadow: none;
    border: 1px solid #ccc;
  }
  .search-form-wrapper .date-block .multi-datepicker-input{
    background-image: none;
    border-radius: 10px;
  }
  .fake-return-date,.remove-return-btn {
    display: none;
  }
  .styled-date-holder {
    padding: 0;
    border: 0 none !important;
    background: none !important;
    height: 40px;
    @media screen and (min-width:$medium){ 
      height: 50px;
    }
    .multi-datepicker-input{
      opacity: 1;
    }
    .styled-date-inner-wrapper{
      display: none;
    }
  }
  .search-route-item{
    align-items: start;
    .origin-destination-block{
      @media (max-width: $medium_){
        margin-left: 0 !important;
        margin-right: 0 !important;
        flex: 0 0 100%;
      }
      width: unset;
      flex: auto;
    }
    .date-block{
      flex: auto; 
      @media (max-width: $medium_){
        margin-top: 10px;
      }
    }
  }
  .date-block.departure-date input.multi-datepicker-input {
    border-radius:10px;
  }
  .date-block.return-date input.multi-datepicker-input {
    border-radius:10px;
  }
  .date-block.departure-date.one-way,.date-block.departure-date.multy-destination {
    input.multi-datepicker-input {
      border-radius: 10px;
    }
  }
  .multy-destination{
    flex: auto;
  }
  &.body .triptype-radio-item .triptype-radio:checked + .triptype-radio-label {
    background-color: #e1e1e1;
    border-color: #e1e1e1;
    color: #222;
  }
  .search-form-wrapper .departure-date.multy-destination{
    width: auto;
  }
  .multy-dest-route .itinerary-label .line{
    display: none;
  }
  .search-route-item{
    display: flex;
    @media (max-width: $medium_){
      flex-wrap: wrap;
      .date-block{
        width: auto;
      }
      .date-block.departure-date.multy-destination,
      .date-block.departure-date.one-way{
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  .passengers-options-holder-card{
    box-shadow: none;
    border-radius: 10px;
  }
  .style-2-adv-slider-holder {
    .adv-item{
      height: 330px;
      background-size: cover;
      background-position: center;
      border-radius: 15px;
      box-shadow: 1px 1px 3px 0 #0000007d;
      margin: 5px;
    }
    .adv-title {
      position: absolute;
      left: 15%;
      right: 15%;
      bottom: 20px;
      z-index: 10;
      background: #ffffffc9;
      text-align: center;
      font-weight: 600;
      font-size: 28px;
      padding: 15px 20px;
      border-radius: 10px;
    }
    .slick-dots {
      display: flex !important;
      justify-content: center;
      li{
        list-style-type: none;
        width: 12px;
        height: 12px;
        margin: 0 8px;
        position: relative;
        border-radius: 50%;
        background: #adadae;
        &.slick-active{
          background: #d1d2d4;
        }
        button{
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          min-width: 0;
          bottom: 0;
          padding: 0;
          font-size: 1px;
          opacity: 0;
        }
      }
      ::marker {
        display: none;
      }
    }
  }
  .destination-item {
    background: #fff;
    margin-bottom: 1rem;
    padding: 15px;
    display: flex;
    justify-content: space-around;
    border: 2px solid #fff;
    border-radius: 10px;
    transition: all .1s linear;
    box-shadow: 1px 1px 5px 0 #0000001a;
    color: #333;
    font-weight: 600;
    font-size: 15px;
    @media (min-width: $medium){
      font-size: 20px;
    }
    span {
      min-width: 75px;
      text-align: center;
      &.itours-icon{
        min-width: 0;
      }
    }
    &:hover{
      border-color:#034fa6 ;
    }
  }
  .style2dest-title{
    font-weight: bold;
    font-size: 16px;
    color: #58595b;
    margin-bottom: 17px;
    @media (min-width: $medium){
      font-size: 22px;
    }
  }
  .style2-destinations-section {
    background: #f6f7f9;
  }
  .namad-item{
    background-color: transparent;
  }
  .footer-namad-item {
    margin-left: 5px;
    margin-right: 5px;
    >span,>img{
      background: #f1f1f1;
      height: 77px;
      width: 77px;
      display: block;
      object-fit: contain;
      border-radius: 10px;
      margin: 0 auto 10px;
    }
  }
  .three-large-icon-section {
    background: #f6f7f8;
    padding: 20px 0;
    @media screen and (min-width:$medium){
      padding: 80px 0;
    }
}
  .three-large-icon-section-inner {
    .item-title{
      font-weight: 600;
      font-size: 15px;
      color: #58595b;
      @media screen and (min-width:$medium){
        font-size: 17px;
      }
    }
    .icon-item {
      padding: 10px;
      margin-bottom: 10px;
    }
    @media (min-width: $small){
      background-color: #fff;
      box-shadow: 1px 1px 8px 0 #0000003d;
      border-radius: 15px;
      display: flex;
      align-items: start;
      justify-content: space-around;
      .icon-item {
        padding: 30px;
      }
      .item-title{
        font-weight: bold;
        font-size: 19px;
      }
    }
    .itours-icon{
      margin-bottom: 15px;
      @media (min-width: $small){
        width: 100px;
        height: 100px;
        margin-bottom: 35px;
      }

    }
  }
  .bottom-footer {
    padding: 0;
    background: transparent;
    hr{
      margin: 0;
    }
  }
  .balance-loading{
    margin-top: 4px;
  }
  .style-2-account .link-style-btn{
    color: #333;
  }
  @media screen and (max-width:$medium){
    .is-logged-in .style-2-account{
      align-self: stretch;
    }
    .style-2-account .button, .track-order-btn {
      padding: 2px 6px;
      line-height: 20px;
    }
    .style-2-account .button{
      padding: 0;
      border: 0 none;
      min-width: 40px;
    }
    .style-2-trackingOrder {
      position: absolute !important;
      left: 0;
      bottom: 0;
    }
  } 
  .style-2-trackingOrder {
    position: relative;
    @media screen and (min-width:$medium_){
      margin-left: 10px;
    }
  }
  .style-2-account {
    display: flex;
    justify-content: space-between;
  }
  .main-menu-wrapper {
    @media screen and (max-width:$medium){
      margin-right: 5px;
      margin-bottom: 10px;
    }
  }
  .is-logged-in .style-2-trackOrderAccount {
    flex-direction: column-reverse;
    align-items: end;
    justify-content: space-between;
    .style-2-trackingOrder{
      margin: 10px 0 0;
    }
  }
  @media screen and (min-width:$medium_){
    .style-2-header-inner {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      min-height: 95px;
    }
    .header-logo-holder {
      text-align: inherit;
    }
  }
  .style-2-trackOrderAccount {
    display: flex;
    align-items: center;
    padding: 10px 0;
    @media screen and (max-width:$medium){
      justify-content: space-between;
      padding: 10px 5px 0;
    }
  }
  .is-home .main-menu-wrapper {
    @media screen and (min-width:$medium_){
      display: none;
    }
  }

  .main-menu .menu-item {
    a{
      color: #555;
      border: 0 none;
      font-weight: 600;
      font-size: 16px;
      &:hover{
        color: #555;
      }
    }
    &.active a{
      color: $purple;
    }
  }
  .account-links {
    .link,.link-style-btn {
      background: #fff;
      padding: 0 10px;
      border-radius: 5px;
      line-height: 40px;
      color: #034fa6;
      font-weight: 600;
      display: inline-block;
    }
  }

  .tracking{
    background-color: #ffffffad;
    backdrop-filter: blur(2px);
    border-radius: 15px;
    .form-group .form-input{
      border-radius: 10px;
      background: #ffffff;
      border-color: #d7d7d7;
    }
  }
  .search-result-page,.hotel-details {
    .mobile-tab-hotel-flight {
      display: none;
    }
    .search-box-holder {
      .autocompleteText input,
      .multi-datepicker-input,
      .nights-column,
      .searchbox-inner .form-group .room-option-toggle-select,
      .searchbox-inner .selected-passengers-input,
      .thickSearchBtn,
      .hotel-search-btn{
        height: 40px;
      }
      .thickSearchBtn,
      .hotel-search-btn{
        min-width: 0;
        padding-left: 0;
        padding-right: 0;
      }
      .passengers-and-cabinClass {
        @media screen and (min-width:$small){
          display: flex;
          .select-class-block {
            flex: 1 0 100px;
          }
          .select-passengers{
            flex:auto;
          }
        }
      }
      .autocompleteText {
        .clear-autocomplete {
          top: 7px;
        }
        .loading-circle.small {
          top: 5px;
        }
      }
       .form-group .form-select.class-select,
       .selected-passengers-input{
         border-radius: 10px;
       }

    }
    .nights-column {
      line-height: 40px;
    }

    .select-passenegers-block {
      min-width: 230px;
    }
  }
  .select-class-block{
    margin-bottom: 10px;
    @media screen and (min-width:$medium){
    margin-bottom: 0;
    }
  }
  @media screen and (max-width:$medium_){    
    .select-passenegers-block {
      position: static;
    }
  }
  .hotel-image.default-image {
    background-size: 90%;
    background-color: #cde5f5;
  } 
  .room-image-holder{
    padding: 10px;
    background-color: #cde5f5;
    &.api-image{
      padding: 0;
      .room-image{
        width: 100%;
      }
    }
  }
  .home-top-slogan .subslogan{
    font-size: 17px;
    margin-top: 20px;
  }
  .mobile-tab-hotel-flight{
    @media screen and (max-width:$small_){
      .home-tabs-holder .home-tab .itours-icon {
        display: none;
      }
    }
  }
  .header-left-links .button{
    padding: 0 10px;
    border-radius: 5px;
    line-height: 40px;
    font-weight: 600;
  }
  .style2-adv-item {
    padding: 10px;
    direction: rtl;
  }  
  .adv-item-image{
    max-width: 100%;
    border-radius: 20px;
    box-shadow: 1px 1px 7px 0 #0000007a;
  }
  .style-2-adv-slider-inner{
    padding: 30px 0;
    margin-bottom: 35px;
    @media screen and (min-width:$medium){
      padding: 70px 0;
      margin-bottom: 0;
    }
    margin-left: -10px;
    margin-right: -10px;
  }

  .adv-item-title, .adv-item-subtitle {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 5px;
    @media screen and (min-width:$medium){
      font-size: 23px;
      margin-top: 10px;
    }
  }
  .adv-item-title{
    color: #000;
    margin-top: 10px;
    @media screen and (min-width:$medium){
      font-size: 23px;
      margin-top: 20px;
    }
  }
  .adv-item-subtitle{
    color: #646464;
  }
  .three-large-icon-item {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    @media screen and (min-width:$medium){
      width: 90px;
      height: 90px;
      margin-bottom: 35px;
    }
    object-fit: contain;
    object-position: bottom;
    display: inline-block;
  }
  .style-2-support-section {
    background: linear-gradient(90deg, #d977b9, #092795);
    padding: 35px 0;
    box-shadow: 0 0 20px 0 #00000099;
    position: relative;
  }
  .style-2-support-inner {
    .email,.phone-number{
      margin: 15px 0;
      @media screen and (min-width:$medium){
        margin: 0 0 30px;
      }
    }
    strong{
      font-size: 18px;
      font-weight: bold;
      @media screen and (min-width:$medium){
        font-size: 17px;
      }
      @media screen and (min-width:$large){
        font-size: 20px;
      }
    }
    @media screen and (min-width:$medium){
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .divider {
        height: 35px;
        border-left: 1px solid #febb02;
        margin: 0 5px;
      }
    }
    color: #fff;

    label{
      font-size: 13px;
    }
    .style-2-support-icon {
      width: 20px;
      margin-left: 10px;
      @media screen and (min-width:$large){
        width: 40px;
        margin-left: 15px;
      }
    }
    p{
      margin-bottom: 0;
      @media screen and (min-width:$large){
        font-size: 16px;
      }
    }
    .support{
      margin-bottom: 10px;
    }
  }
  .style2-destinations-title{
    margin-bottom: 20px;
    @media screen and (min-width:$large){
      margin-bottom: 80px;
      font-weight: bold;
    }
  } 
  .style2footer-row{
    @media screen and (min-width:$medium){
      display: flex;
      .style2footer-col1,.style2footer-col2{
        flex: 0 0 20%;
      }
      .style2footer-col3{
        flex: 0 0 60%;
        align-self: center;
      }
    }
  }
  .namads-holder{
    padding: 50px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media screen and (min-width:$large){
      justify-content: space-between;
    }
  }
  .footer-namad-item{
      width: auto;
  }
  .style-2-footer-bottom {
    padding: 25px 0;
    @media screen and (min-width:$medium){
      display: flex;
      justify-content: space-between;
    }
  }
  .namads-holder {
    & > a, & > span, & > img{
      padding: 0;
    }
  }
  .button,
  .button[type="submit"] {
    &.color-1-btn,
    &.blue-btn{
      background: #034fa6;
      &:hover {
        @media (hover: hover) {
          background: #034fa6;
        }
      }
      &:focus{
        background: #034fa6;
      }
      &.loading-btn,&.loading-btn[disabled]{
        background-color: #034fa6;
      }      
    }
  }
  .blue, .color-1 {
    color: #034fa6;
  }
  .hotel-dates-group .checkout-column {
    margin: 0 10px 10px;
  }  
  .style2footer-col1, .style2footer-col2 {
    font-weight: 600;
  }
  .footer-title{
    font-weight: bold;
  }
  @media screen and (max-width:$small_){
    .hotelResult .button.results-sort-btn.domestic-hotel-sort-btn {
      min-width: 0;
      font-size: 12px;
      padding-left: 20px;
      padding-right: 5px;
      &::after {
        left: 5px;
      }
    }
  }
  .home-tab .svgIcon {
    display: none;
    @media screen and (min-width:$medium_){
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 0 8px;
    }
    &.active{
      fill: #004ba8;
    }
  }
  .loading-full-page .loading-section{
    border-radius: 50px;
  }
  .main-content {
    min-height: calc(100vh - 465px);
  }
  .package-hotel-list,.selected-package,.package-flight-list{
    .loading-icon-style2 {
      display: none;
    }
  }
  .summary-detail-link {
    color: #034fa6;
    border-bottom: 1px solid;
    display: inline-block;
    margin: 0 7px;
    cursor: pointer;
  }
}
.safarmarket-footer-inner .footer-namad-item{
  border-radius: 12px;
}