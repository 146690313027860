@import "./base";

.padide-com-theme {
  .search-result-page .selected-package{
    margin-top: 15px;
  }
  .package-hotel-footer .red-btn {
    background-color: #8bc34a;
  }
  .package-avails-row .package-hotel-item .package-hotel-footer{
    border-top-color: #f1f1f1;
    background-color: #f1f1f1;
    padding: 8px 15px;
  }
  &:not(.ltr) &{
    .package-hotel-main {
      min-height: 166px;
    } 
  }  
  &.ltr {  
    .package-hotel-main {
      min-height: 168px;
    } 
  }
  .multi-datepicker-input{
    background-image: url("../image/blue-calendar.svg");
  }
  &.body.ltr .multi-datepicker-input {
    background-position: left 7px top 6px;
  } 
  &.body:not(.ltr) .multi-datepicker-input {
    background-position: right 7px top 6px;
  } 
  
  .hotelResult .page-container.no-padding-mobile.search-result-page {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media screen and (max-width: $small_) {
    .room-guests.multyroom .room-number {
      color: inherit;
      display: inline-block;
      position: static;
      top: 0;
    }
  }

  .main-menu .itours-icon {
    display: none;
  }
  &.body {
    background-color: rgba(68, 110, 156, 0.12);
    .color-1-btn:not(:disabled) {
      background-color: $yellow;
      color: #fff;
      border-color: $yellow;
      &:active {
        background-color: $yellow;
      }
    }
    .loading-logo {
      width: 70px;
    }

    .navbar .logo-holder .logo {
      width: 70px;
      margin: auto;
      img {
        width: 100%;
        margin-top: 8px;
      }
    }
    .padideh-home-top {
      @media screen and (min-width: $medium) {
        display: flex;
        padding: 40px 0;
      }
    }
    .adv-banner {
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
      flex: 0 0 300px;
      cursor: pointer;
      background-size: cover;
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.15);
      }
    }
    .newletter-section {
      background: #ffffff;
      padding: 35px 0;
    }
    .home-search-section {
      position: relative;
      @media screen and (min-width: $medium) {
        min-height: calc(100vh - 323px);
        display: flex;
        justify-content: center;
        align-items: center;
        & > div{
          flex: 1 1;
        }
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(
          0,
          rgba(255, 255, 255, 0.7),
          rgba(255, 255, 255, 0)
        );
      }
    }
    .searchbox-inner {
      padding: 10px 0;
      @media screen and (min-width: $medium) {
        padding: 15px 20px;
      }
    }

    .searchBar .ant-calendar-picker .anticon {
      background-image: url("../image/blue-calendar.svg");
      height: 22px;
      background-size: contain;
    }
    .search-box-holder .loading-circle.small {
      width: 31px;
      top: 2px;
      height: 31px;
    }

    .search-box-holder {
      @media screen and (max-width: $medium_) {
        background-color: #fff;
        background-color: transparent;
      }
      .tab-holder {
        position: relative;
      }
    }

    .hotel-destination-holder .autocompleteText input {
      background-image: url("../image/blue-location.svg");
      background-size: 26px;
    }

    .bottom-footer {
      background-color: #404041;
      color: #fff;
      .footer-logo {
        display: none;
        // width: 100px;
        // margin-bottom: 10px;
        & + div {
          small {
            font-size: 13px;
          }
        }
      }
    }
    .form-group .form-control,
    .form-group .form_control,
    .form-group .ant-calendar-picker-input {
      box-shadow: none;
    }
    .result-inline-search-section {
      .children-ages {
        padding: 0;
        margin: 0;
      }
      .searchBtn {
        width: 100%;
        max-width: 100%;
        height: 35px;
        @media screen and (min-width: $medium) {
          &.flight-search-btn {
            margin-top: 13px;
          }
          &.hotel-search-btn {
            margin-top: 17px;
          }
        }
      }
    }
    .flight .results-sorting-block {
      padding-top: 23px;
      padding-bottom: 5px;
      .sort-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        img {
          display: none;
        }
      }
    }
    .hotelResult .results-sorting-block {
      padding-top: 15px;
      padding-bottom: 2px;
    }
    .result-inline-search-section {
      & + .section-vertical-padding {
        padding-top: 10px;
      }
      .searchbox-inner {
        padding: 10px 0;
        .triptype-radio {
          margin-bottom: 0;
        }
      }
      @media screen and (min-width: $small) {
        background: #404041;
        label {
          color: #fff;
        }
        .radio-item {
          .radio-label .radio-mark {
            border-color: #fff;
          }
          .custom-radio:checked + .radio-label .radio-mark:before {
            background-color: #fff;
          }
        }
      }

      // @media screen and (max-width: $medium_) {
      //   display: none;
      // }
      .search-box-holder {
        box-shadow: none;
        background: none;
        background: transparent;
      }
      .form-label {
        font-weight: 600;
        display: block !important;
      }
    }
    main.main-content {
      min-height: calc(100vh - 193px);
    }
    .airline-filter-holder {
      padding: 10px 10px;
      background-color: #fff;
      .cell {
        background-color: #f1f1f1;

        & + .cell {
          background-color: #f1f1f1;
          border-top: 1px solid #ddd;
        }
      }
    }

    //flight result:
    .avails-holder .flight-result-item {
      display: block;
      .item-main {
        display: block;
      }
      .item-footer {
        background: #f1f1f1;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 8px 10px;
        border: 0 none;
        .price {
          flex-direction: row;
          .value {
            margin-right: 8px;
          }
        }
        .button.red-btn {
          background-color: #8bc34a;
        }
      }
    }
    .flightDetails {
      .button.red-btn {
        background-color: #8bc34a;
      }
    }
    .filters-holder {
      // border: 1px solid #bcc7c4;
      // padding: 0 7px;
      // margin-top: 10px;
      // margin-right: 0 !important;
      // margin-left: 0 !important;
      // background: #fff;
      // box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
      .checkbox-label {
        .itours-icon {
          display: none;
        }
      }
      .ant-collapse-item .ant-collapse-header {
        padding-left: 10px;
        padding-right: 10px;
        &::after {
          display: none;
        }
      }
      .normal-title {
        padding: 0 10px;
        margin: 0;
      }
      .has-fake-magnifirer-icon {
        padding: 0 10px;
        margin-bottom: 0;
      }
      .filter-header {
        padding: 15px 0;
        margin: 0;
      }
    }
    .airline-table-holder {
      border: 0 none;
    }
    .airline-filter-row > div {
      flex: 0 0 160px;
    }
    .airline-filter-item {
      position: relative;

      img.airline-logo {
        position: absolute;
        width: 55px;

        font-size: 10px;
        line-height: 1;
        color: #bbb;
        overflow: hidden;
      }
    }
    .airline-table-header {
      position: relative;

      border: 1px solid #ccc;

      overflow: hidden;
      margin-top: 3px;
      .airline {
        position: absolute;

        height: 60px;
      }
    }
    .itours-tab-holder .itours-tab-list .itours-tab-btn.active {
      border: 2px solid #8bc34a;
      background-color: rgb(255, 255, 255);
      box-shadow: none;
    }
    .ant-steps {
      display: none;
    }
    .ticket-style-holder {
      border: 1px solid #ccc;
      .button.red-btn {
        background-color: #8bc34a;
      }
      .ticket-style-section {
        border: 0 none;
        &:not(:last-child) {
          border-bottom: 1px solid #ccc;
        }
        &:first-child::before,
        &:first-child::after {
          display: none;
        }
      }
    }
    .icon-hint-holder {
      margin-bottom: 10px;
      .icon {
        display: none;
      }
      .hint-inner {
        color: inherit;
        background: transparent;
        padding: 0;
      }
    }
    .passenger-title span.itours-icon {
      display: none;
    }
    .hotel-result-item .hotel-image {
      margin: 0;
    }
    .has-fake-magnifirer-icon input.form-input {
      padding-left: 10px;
      padding-right: 10px;
    }
    .has-fake-magnifirer-icon {
      &::before,
      &::after {
        display: none;
      }
    }
    .hotel-result-item,
    .hotel-summary-card,
    .contact-us {
      .button.red-btn {
        background-color: #8bc34a;
      }
      .hotel-footer {
        border: 0 none;
      }
      .hotel-name {
        color: #8bc34a;
        font-size: 18px;
        margin-bottom: 10px;
      }
    }
    .feature-list .feature-items div {
      color: #ff9800;
      padding: 0 5px;

      border: 1px solid #ffc107;
      font-size: 12px;
      line-height: 22px;
      border-radius: 10px;
    }
    .package-hotel-item {
      .package-hotel-details{
        .feature-list{
          margin-top: 3px;
          max-height: 20px;
          overflow: hidden;
        }
      }
      .feature-list .feature-items div {
        padding: 0 2px;
        font-size: 12px;
        line-height: 16px;
        border-radius: 5px;
      }
    }
    .checkbox-holder {
      font-size: 14px;
    }
    .checkbox-label::before {
      width: 12px;
      height: 12px;
      border: 1px solid #bdbdbd;
      border-radius: 0;
    }
    .sidebar-item {
      padding: 10px 0;
      border: 0 none;
    }
    .hotelResult .button.results-sort-btn {
      min-width: 0;
      box-shadow: none;
      border: 0 none;
      background-color: transparent;
      &.disable {
        color: #555;
        &::after {
          border-top: 2px solid #555;
          border-right: 2px solid #555;
        }
      }
    }
    .hotel-result-item {
      background-color: #fff;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
      display: flex;
      margin-bottom: 15px;
      border: 1px solid #e5e5e5;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    }
    .page-title {
      border: 0 none;
      padding-bottom: 0;
    }
    .result-page-search-bar .headerInformation {
      display: none;
    }
    .search-box-holder.inline .searched-information .fakeForm .textbox {
      border: 0 none;
      margin: 0;
    }
    .searched-information {
      border: 1px solid #e5e5e5;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    }
    .map-btn-holder {
      background-image: none;
      height: auto;
      display: block;
      .map-view-btn {
        width: 100%;
        border: 0 none;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        background: #ef4050;
        color: #fff;
        margin-bottom: 5px;
        margin-top: 10px;
      }
    }
    .searchShow {
      background: #fff;
      padding: 0 10px;
      margin-top: 10px;
      border: 1px solid #e5e5e5;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
      .showSearch {
        display: none;
      }
    }
    .blue-border-right {
      border: 1px solid #ccc;
    }
    .hotel-detail-searchholder {
      display: none;
    }
    .room-select-card {
      margin-top: 20px;
      .button.red-btn {
        background-color: #8bc34a;
      }
      .col-small-12.col-medium-2.hidden-xsmall.hidden-small {
        display: none;
        & + .col-small-12 {
          width: 100%;
        }
      }
    }
    .hotel-head-info .button.red-btn {
      display: none;
    }
    .hotel-summary-card .hotel-head-info {
      display: block;
      .hotel-image.main-hotel-image {
        height: 170px;
        margin-bottom: 10px;
        margin-right: 0;
        margin-left: 0;
      }
    }
    .button.green-btn,
    .button[type="submit"].green-btn {
      background-color: #8bc34a;
    }
    main.main-content > .section-vertical-padding.bg-white {
      background-color: transparent;
    }
    .search-box-holder .loading-circle.small {
      &::after,
      &::before {
        top: 3px;
      }
    }
    .airline-autocomplete-holder .autocompleteText input {
      padding-top: 8px;
      & + .clear-autocomplete {
        right: 3px;
      }
    }
  }
  &.body:not(.ltr) {
    .adv-banner {
      background-image: url("../image/phuket.jpg");
      background-position: left bottom;
    }
    .autocompleteText .clear-autocomplete {
      left: 2px;
      top: 5px;
      width: 25px;
      height: 25px;
    }
    .flight-origin-holder .autocompleteText input {
      background-image: url("../image/takeoff-blue-rtl.svg");
      background-position: right 5px top 4px;
      padding-top: 8px;
      background-size: 25px;
    }
    .flight-destination-holder .autocompleteText input {
      background-image: url("../image/landing-blue-rtl.svg");
      background-position: right 5px top 4px;
      background-size: 25px;
      padding-top: 8px;
    }
    .deleteable-route {
      padding-left: 40px;
      .delete-route {
        left: 0;
        top: 23px;
        position: absolute;
      }
    }
    .PDatePicker-holder .PDatePicker-input {
      background-position: right 5px top 4px;
      background-image: url("../image/blue-calendar.svg");
    }
    .hotel-destination-holder .autocompleteText input {
      background-position: right 5px top 4px;
    }
    .bottom-footer {
      .footer-logo {
        & + div {
          text-align: right;
        }
      }
    }
    .airline-filter-holder {
      .cell {
        border-right: 1px solid #ddd;
      }
    }
    .airline-filter-item {
      padding-right: 55px;
      img.airline-logo {
        right: 0;
      }
    }
    .airline-table-header {
      padding-right: 60px;
      border-radius: 0 10px 10px 0;

      .airline {
        right: 0;
      }
    }
    .feature-list .feature-items div {
      margin: 0 7px 5px 0;
    }
  }
  &.body.ltr {
    font-family: "sourceSans";
    .adv-banner {
      background-image: url("../image/phuket.jpg");
      background-position: left bottom;
    }
    .autocompleteText .clear-autocomplete {
      right: 2px;
      top: 5px;
      width: 25px;
      height: 25px;
    }
    .flight-origin-holder .autocompleteText input {
      background-image: url("../image/takeoff-blue.svg");
      background-position: 5px 4px;
      background-size: 25px;
    }
    .flight-destination-holder .autocompleteText input {
      background-image: url("../image/landing-blue.svg");
      background-position: 5px 4px;
      background-size: 25px;
    }
    .deleteable-route {
      padding-right: 40px;
      .delete-route {
        right: 0;
        top: 23px;
        position: absolute;
      }
    }
    .hotel-destination-holder .autocompleteText input {
      background-position: 5px 4px;
    }

    .bottom-footer {
      .footer-logo {
        & + div {
          text-align: left;
        }
      }
    }
    .airline-filter-holder {
      .cell {
        border-left: 1px solid #ddd;
      }
    }
    .airline-filter-item {
      padding-left: 55px;
      img.airline-logo {
        left: 0;
      }
    }
    .airline-table-header {
      padding-left: 60px;
      border-radius: 10px 0 0 10px;

      .airline {
        left: 0;
      }
    }
    .feature-list .feature-items div {
      margin: 0 0 5px 7px;
    }
  }

}