@import "base";

.start-loading {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}
.home-top-slogan {
  font-size: 36px;
  font-weight: 600;
  color: rgb(104, 66, 134);
  padding-bottom: 45px;
  padding-top: 90px;
  display: block;
  margin-bottom: 0;
  text-align: center;
  letter-spacing: -1px;
  .subslogan {
    display: block;
    font-size: 12px;
    letter-spacing: 0;
    font-weight: bold;
    margin-top: 5px;
  }
}
.thumbnail-link {
  position: relative;
  //cursor: pointer;
  @include border-radius(10px);
  overflow: hidden;
  @media screen and (min-width: $medium) {
    margin: 7px;
  }
}

.bg-on-hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  @include transition(all 0.1s linear);
  text-shadow: 1px 1px 4px #0000009e;
  color: #fff;
  .show-on-hover {
    display: block;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: initial;
    @media screen and (min-width: $medium) {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
    }
    height: 40px;
    width: 85px;
    margin: auto;
    position: relative;
    @include transition(all 0.2s linear);
  }
  @media screen and (max-width: $medium_) {
    background-color: rgba(0, 0, 0, 0.5);
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
    text-shadow: none;
    .show-on-hover {
      @media screen and (min-width: $medium) {
        max-height: 40px;
        opacity: 1;
      }
    }
  }

  .title {
    .cityName {
      position: relative;
      margin-bottom: 5px;
      display: block;
      padding-bottom: 5px;
      &::after {
        content: "";
        display: block;
        position: absolute;
        height: 2px;
        background-color: #fff;
        right: 20%;
        left: 20%;
        bottom: 0;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.6);
      }
    }
    .hotel-flight-search {
      position: absolute;
      top: 5px;
      width: 30px;
      height: 30px;
      transform: scale(1);
      transition: all 0.05s linear;
      &:first-child {
        left: 5px;
      }
      &:last-child {
        right: 5px;
      }
      &:hover {
        transform: scale(1.3);
      }
    }
    font-size: 30px;
    @media screen and (max-width: $medium_) {
      font-size: 20px;
    }
    font-weight: bold;
    margin: 0;
    letter-spacing: 2px;
    position: absolute;
    line-height: initial;
    &.top-left {
      left: 20px;
      top: 10px;
      text-align: left;
    }
    &.top-right {
      right: 20px;
      top: 10px;
      text-align: right;
    }
    &.buttom-left {
      left: 20px;
      bottom: 10px;
      text-align: left;
    }
    &.center {
      left: 50%;
      top: 50%;
      text-align: center;
      transform: translate(-50%, -50%);
    }

    &.bottom-right {
      right: 20px;
      bottom: 10px;
      text-align: right;
    }

    /*         &::after {
            content: "";
            display: block;
            position: absolute;
            top: 100%;
            height: 0;
            background-color: #fff;
            width: 40px;
            right: 50%;
            margin-right: -20px;
            @include transition(all .1s linear);
        } */
  }
}
.pointer {
  cursor: pointer;
}

.city-link {
  &.large {
    height: 170px;

    @media screen and (max-width: $small_) {
      height: 200px;
    }

    @media screen and (min-width: $medium) {
      height: 280px;
    }
  }

  &.medium {
    height: 150px;
    @media screen and (max-width: $small_) {
      height: 200px;
    }

    @media screen and (min-width: $medium) {
      height: 250px;
    }
  }

  &.small {
    height: 120px;
    @media screen and (max-width: $small_) {
      height: 200px;
    }

    @media screen and (min-width: $medium) {
      height: 220px;
    }
  }

  display: block;
  background-size: cover;
  background-position: center;
  position: relative;
}

.city-beijing {
  background-image: url("../image/cities/beijing.jpg");
}

.city-dubai {
  background-image: url("../image/cities/dubai.jpg");
}

.city-ist {
  background-image: url("../image/cities/ist.jpg");
}

.city-rome {
  background-image: url("../image/cities/rome.jpg");
}

.city-phuket {
  background-image: url("../image/cities/phuket.jpg");
}
.city-bali {
  background-image: url("../image/cities/bali.jpg");
}
.city-delhi {
  background-image: url("../image/cities/delhi.jpeg");
}
.city-baku {
  background-image: url("../image/cities/baku.jpg");
}
.city-copenhagen {
  background-image: url("../image/cities/Copenhagen.jpg");
}

.newletter-section {
  background: #d2d2d2;
  padding: 15px 0;
}
.newsleter-inner {
  .normal-title {
    margin-bottom: 0;
  }

  p {
    margin: 0;
  }
}

.landmarks-section {
  padding: 20px 0;
  background-color: #fff;
}
.landmark-link {
  cursor: pointer;
  text-decoration: none;
  padding: 15px 0;
  display: block;
  color: #333;
  font-weight: 600;
  @include transition(all 0.1s linear);
  @include border-radius(15px);

  &:hover {
    background-color: rgb(245, 245, 245);
    color: #058bd6;
    /*         .landmarg-icon{
            background-image: url('../image/cities/cities-blue.svg');
        } */
  }

  .landmarg-icon {
    background-repeat: no-repeat;
    height: 100px;
    margin-bottom: 10px;
    //@include transition(all .1s linear);
    background-image: url("../image/cities/cities-landmark.svg");

    //as IE dosnt support svg background:
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      background-image: url("../image/cities/cities-landmark.png");
    }

    background-size: 600px;
    width: 100px;
    margin-right: auto;
    margin-left: auto;
    @media screen and (max-width: $small_) {
      width: 70px;
      height: 70px;
      background-size: 420px;
      &.landmark-dubai {
        background-position: 0 0px;
      }

      &.landmark-paris {
        background-position: -280px 0px;
      }

      &.landmark-delhi {
        background-position: -140px 0px;
      }

      &.landmark-london {
        background-position: -70px 0px;
      }

      &.landmark-moscow {
        background-position: -350px 0;
      }

      &.landmark-sydney {
        background-position: -210px 0;
      }
    }
  }

  .title {
    display: block;
  }
}

.landmark-dubai {
  background-position: 0 0px;

  .landmark-link:hover & {
    background-position: 0 -100px;
  }
}

.landmark-paris {
  background-position: -400px 0px;

  .landmark-link:hover & {
    background-position: -400px -100px;
  }
}

.landmark-delhi {
  background-position: -200px 0px;

  .landmark-link:hover & {
    background-position: -200px -100px;
  }
}

.landmark-london {
  background-position: -100px 0px;

  .landmark-link:hover & {
    background-position: -100px -100px;
  }
}

.landmark-moscow {
  background-position: -500px 0;

  .landmark-link:hover & {
    background-position: -500px -100px;
  }
}

.landmark-sydney {
  background-position: -300px 0;

  .landmark-link:hover & {
    background-position: -300px -100px;
  }
}

.inline-logo {
  width: 110px;
  display: inline-block;
  vertical-align: baseline;
  margin: 0 5px;
  position: relative;
  top: 15px;
  @media screen and (max-width: $medium_) {
    width: 85px;
  }
}

.section-title {
  font-size: 35px;
  font-weight: normal;
  margin: 0 0 20px;
  line-height: 1.4;
  @media screen and (max-width: $medium_) {
    font-size: 18px;
  }
}

.body .home-tabs-holder {
  .home-tab {
    background-color: rgba(255, 255, 255, 0.5);

    border-radius: 4px 4px 0 0;
    border: 0 none;
    outline: none;
    padding: 13px 40px;
    line-height: 20px;
    display: inline-block;
    color: inherit;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    text-align: center;
    body:not(.ltr) & {
      margin-left: 1px;
    }
    body.ltr & {
      margin-right: 1px;
    }
    &.home-active-tab {
      background-color: rgba(0, 0, 0, 0.6);
      color: #fff;
    }
  }
}

.search-box-holder {
  background-color: rgba(0, 0, 0, 0.5);
  background-color: rgba(230, 215, 187, 0.52);
  @media screen and (min-width: $medium) {
    border-radius: 10px;
  }
  .tab-holder {
    border-radius: 10px 10px 0 0;
    overflow: hidden;
  }
  @media (min-width: 600px) {
    body:not(.padide-com-theme):not(.naminTravel-theme__) & {
      .radio-item {
        .custom-radio {
          &:checked {
            & + .radio-label .radio-mark {
              &::before {
                background: #fff;
              }
            }
          }
        }
        .radio-label {
          .radio-mark {
            border-color: #fff;
          }
        }
      }
    }
  }
}

.accept-rules-flex {
  @media screen and (max-width: $medium_) {
    display: block;
    font-size: 14px;
    line-height: 1.5;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  .button {
    flex: 1 0 150px;
  }
}
.accept-rule-inner {
  font-size: 15px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  padding: 15px 0;
  z-index: 1000;
  color: #fff;
  line-height: 17px;
  strong {
    margin-bottom: 10px;
    display: block;
  }
  p {
    margin-bottom: 0;
  }
}
//home new design:
.search-route-item {
  @media (min-width: 600px) {
    display: flex;
  }

  .origin-destination-block {
    width: 100%;
    body:not(.ltr) & {
      margin-left: 7px;
    }
    body.ltr & {
      margin-right: 7px;
    }
    .swap-vert {
      background-color: #fff;
      background-image: url("../image/swap_vert.svg");
      background-size: contain;
      min-width: 0;
      width: 25px;
      height: 25px;
      border: 1px solid #bcc7c4;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      margin-top: -12px;
      right: 75px;
      @media (max-width: 600px) {
        right: 35px;
      }
      outline: none;
    }
  }
  .date-block {
    flex: 0 0 135px;
    .itours-icon {
      position: absolute;
      top: 10px;
      width: 24px;
      height: 24px;
      body:not(.ltr) & {
        right: 10px;
      }
      body.ltr & {
        left: 10px;
      }
    }
  }
}
.select-passengers {
  position: relative;
  &.large-select-passenger {
    .selected-passengers-input{
      height: 90px;
      border-radius: 4px;
    }
  }
  .selected-passengers-input {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    
    background: #fff;
    border: 1px solid #bcc7c4;
    border-radius: 0 0 4px 4px;
    padding: 6px 10px;
    outline: none;
    font-size: 15px;
    height: 45px;
    line-height: 1;
    width: 100%;
    margin-top: 1px;
    cursor: pointer;
    text-align: inherit;
    background-image: url("../image/down-caret.svg");
    background-repeat: no-repeat;
    background-size: 25px;
    @media screen and (max-width: $small_) {
      background-size: 17px;
      font-size: 12px;
    }
    body:not(.ltr) & {
      background-position: left 0 center;
      padding-left: 15px;
      padding-right: 10px;
      @media screen and (max-width: $small_) {
        padding-left: 12px;
        padding-right: 8px;
      }
      &[dir="ltr"] {
        background-position: right 0 center;
        padding-right: 15px;
        padding-left: 10px;
        @media screen and (max-width: $small_) {
          padding-left: 8px;
          padding-right: 12px;
        }
      }
    }
    body.ltr & {
      background-position: right 0 center;
      padding-right: 15px;
      padding-left: 10px;
      @media screen and (max-width: $small_) {
        padding-left: 8px;
        padding-right: 12px;
      }
    }
  }
}
.select-passenegers-block {
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 4px;
  @media screen and (min-width: $medium) {
    z-index: 1;
    position: absolute;
    top: 100%;
    width: 100%;
    right: 0;
  }
  @media screen and (min-width: 600px) {
    box-shadow: 0 0 4px grey;
  }
  margin-top: 2px;
  padding: 10px 0;
  .change-quantity {
    display: inline-block;
    button {
      width: 24px;
      height: 24px;
      display: inline-block;
      min-width: 0;
      vertical-align: middle;
      padding: 0;
      border-radius: 50%;
      outline: none;
      border: 0 none;
      background-color: #fff;
      background-color: transparent;
      .itours-icon {
        width: 22px;
        height: 22px;
      }
    }
    .passenger-input {
      border: 0 none;
      outline: none;
      width: 30px;
      text-align: center;
      line-height: 25px;
    }
  }
  .type-row {
    padding: 5px 10px;
  }
}
.thickSearchBtn {
  height: 45px;
  @media (min-width: $medium) {
    height: 91px;
    .btn-text {
      display: block;
    }
  }
}
.search-form-wrapper {
  position: relative;
  z-index: 2;
  .search-box-holder {
    @media (min-width: $medium) {
      .form-group .form-input,
      .PDatePicker-holder .PDatePicker-input,
      .multi-datepicker-input,
      .fake-return-date,
      .form-group .form-select,
      .selected-passengers-input,
      .select-passenegers-block,
      .hotel-dates-group .nights-column,
      .passengers-options-holder,
      .ant-input {
        border: 0 none;
      }
    }
    @media (max-width: $medium_) {
      .form-group .form-select,
      .form-group .form-input {
        outline: none;
      }
    }
  }
}
.home-search-section {
  @media (min-width: $medium) {
    transition: all .3s linear;
    background-image: url("../image/bg(7).jpg");
    background-size: cover;
    background-position: center;
  }
  @media (max-width: $medium_) {
    background-image: none !important;
    margin-top: 1px;
  }
  .search-form-wrapper {
    @media (min-width: $medium) {
      background-color: rgba(0, 0, 0, 0.6);
      .Home.hotel &, .Home.package & {
        padding: 70px 0 65px;
      }
    }
    padding: 20px 0 35px;
  }
}
.search-form-wrapper {
  .room-guests {
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
    position: relative;
    .form-group {
      @media (min-width: $medium) {
        margin-bottom: 0;
      }
    }
    .remove-room-btn {
      position: absolute;
      border-radius: 50%;
      min-width: 0;
      height: 30px;
      width: 30px;
      padding: 0;

      body:not(.ltr) & {
        left: 0;
      }
      body.ltr & {
        right: 0;
      }
      top: 50%;
      margin-top: -15px;
      @media (max-width: 599px) {
        top: 0;
        margin-top: -16px;
      }
    }
  }
  .validator-label {
    position: absolute;
    z-index: 10;
    color: #fff;
    background: $purple;
    font-weight: normal;
    padding: 5px 15px;
    top: 100%;
    margin-top: 11px;
    border-radius: 3px;
    white-space: nowrap;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.7);
    &::before {
      content: "";
      display: block;
      border: 9px solid;
      border-color:  transparent transparent $purple transparent;
      position: absolute;
      top: -18px;
    }
    body:not(.ltr) & {
      right: 0px;
      &::before {
        right: 15px;
      }
    }
    body.ltr & {
      left: 0px;
      &::before {
        left: 15px;
      }
    }
  }
  .search-box-holder {
    background: transparent;
    box-shadow: none;
    .hasValidationError .form-group .form-select.no-border-select {
      color: $purple;
    }
    @media (min-width: $medium) {
      label {
        color: #fff;
      }
    }
    .form-group .form-select {
      height: 45px;
      border-radius: 4px;
      &.class-select {
        border-radius: 4px 4px 0 0;
      }
      &.no-border-select {
        @media (min-width: $medium) {
          border: 0 none;
          line-height: 20px;
          height: 25px;
          display: inline-block;
          width: auto;
          font-weight: bold;
          color: $baseColor;
        }
        body:not(.ltr) & {
          padding: 0 5px 0 20px;
        }
        body.ltr & {
          padding: 0 20px 0 5px;
        }
        outline: none;
        min-width: 0;
      }
    }
    .hotel-destination-holder .form-input {
      height: 45px;
      border-radius: 4px;
    }
    .plus {
      color: #fff;
      background: #bbb;
      width: 20px;
      height: 20px;
      display: inline-block;
      border-radius: 50%;
      text-align: center;
      font-size: 22px;
      position: relative;
      top: 3px;
      body:not(.ltr) & {
        line-height: 25px;
      }
      body.ltr & {
        line-height: 20px;
      }
    }
  }
  .origin-destination-block {
    .flight-origin-holder .form-input {
      border-radius: 4px 4px 0 0;
      height: 45px;
    }
    .flight-destination-holder .form-input {
      border-radius: 0 0 4px 4px;
      height: 45px;
      margin-top: 1px;
    }
  }
  .fake-return-date {
    background-color: #fff;
    border: 1px solid $border-color;
    body:not(.ltr) & {
      border-radius: 4px 0 0 4px;
      background-image: url("../image/right-date.svg");
    }
    body.ltr & {
      border-radius: 0 4px 4px 0;
      background-image: url("../image/left-date.svg");
    }
    background-position: center top 10px;
    padding: 15px 5px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: 40px;
    line-height: 17px;
    font-size: 14px;
    @media (max-width: 599px) {
      .body & {
        display: none;
      }
    }
  }
  .date-block {
    @media (max-width: 599px) {
      width: calc(50% - 1px);
      display: inline-block;
      vertical-align: middle;
      margin-top: 15px;
    }
    input.PDatePicker-input,
    .ant-calendar-picker-input,
    .multi-datepicker-input {
      height: 91px;
      outline: none;
    }
  }
  .departure-date {
    body:not(.ltr) & {
      margin-left: 1px;
      .PDatePicker-input,
      .ant-calendar-picker-input,
      .multi-datepicker-input {
        border-radius: 0 4px 4px 0;
      }
    }
    body.ltr & {
      margin-right: 1px;
      .PDatePicker-input,
      .ant-calendar-picker-input,
      .multi-datepicker-input {
        border-radius: 4px 0 0 4px;
      }
    }
    &.multy-destination {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      .PDatePicker-input,
      .ant-calendar-picker-input,
      .multi-datepicker-input {
        .body & {
          border-radius: 4px;
        }
      }
    }
    &.one-way {
      @media (max-width: 599px) {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        .PDatePicker-input,
        .ant-calendar-picker-input,
        .multi-datepicker-input {
          .body & {
            border-radius: 4px;
          }
        }
      }
    }
  }
  .return-date {
    body:not(.ltr) & {
      .PDatePicker-input,
      .ant-calendar-picker-input,
      .multi-datepicker-input {
        border-radius: 4px 0 0 4px;
      }
    }
    body.ltr & {
      .PDatePicker-input,
      .ant-calendar-picker-input,
      .multi-datepicker-input {
        border-radius: 0 4px 4px 0;
      }
    }
  }
}
.multy-dest-route {
  position: relative;
  .itinerary-label {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    .line {
      margin: 0 2px;
      height: 2px;
      background: rgba(255, 255, 255, 0.7);
      @media (max-width: 599px) {
        background-color: $border-color;
      }
      width: 100%;
      display: block;
    }
  }
  .delete-route {
    background: $purple;
    border-radius: 50%;
    flex: 0 0 25px;
    height: 25px;
    min-width: 0;
    padding: 0;
    align-self: center;
    outline: none;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    body:not(.ltr) & {
      margin-right: 5px;
    }
    body.ltr & {
      margin-left: 5px;
    }
    @media (max-width: $medium_) {
      margin: 0;
      position: absolute;
      top: -2px;
      body:not(.ltr) & {
        left: 0px;
      }
      body.ltr & {
        right: 0px;
      }
      width: 30px;
      height: 30px;
      .itours-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
  @media (min-width: $medium) {
    .deleteable-route.search-route-item {
      body:not(.ltr) & {
        margin-left: -30px;
      }
      body.ltr & {
        margin-right: -30px;
      }
    }
  }
}
.add-destination-holder {
  .itours-icon {
    background-color: #fff;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    border: 3px solid #fff;
  }
  margin-top: 10px;
  .link-style-btn {
    color: #fff;
    font-weight: normal;
    &:hover,
    &:focus {
      color: rgba(255, 255, 255, 0.7);
    }
    @media (max-width: 599px) {
      color: inherit !important;
    }
  }
  .add-flight-icon {
    display: inline-block;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    background: #fff;
    color: #848484;
    font-size: 20px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid $border-color;
  }
}
.remove-return-btn {
  background: #23a5b9;
  position: absolute;
  z-index: 2;
  top: 0;
  body:not(.ltr) & {
    left: 0;
  }
  body.ltr & {
    right: 0;
  }
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 16px;
  font-size: 27px;
  color: #fff;
  font-family: sans-serif;
  border-radius: 3px;
  cursor: pointer;
  @media (max-width: $medium_) {
    width: 30px;
    height: 30px;
    line-height: 26px;
  }
}
.hotel-dates-group {
  display: flex;
  .nights-column {
    background: #fff;
    border: 1px solid $border-color;
    width: 45px;
    height: 45px;
    display: block;
    flex: 1 1 100px;
    line-height: 45px;
    text-align: center;
    white-space: nowrap;
    body:not(.ltr) & {
      border-radius: 4px 0 0 4px;
    }
    body.ltr & {
      border-radius: 0 4px 4px 0;
    }
  }
  .checkout-column {
    flex: 1 1 40%;
    margin: 0 1px 10px;
    .PDatePicker-input,
    .ant-calendar-picker-input,
    .multi-datepicker-input {
      border-radius: 0;
      height: 45px;
    }
  }
  .checkin-column {
    flex: 1 1 40%;
    margin-bottom: 10px;
    .PDatePicker-input,
    .ant-calendar-picker-input,
    .multi-datepicker-input {
      border-radius: 0;
      height: 45px;
      body:not(.ltr) & {
        border-radius: 0 4px 4px 0;
      }
      body.ltr & {
        border-radius: 4px 0 0 4px;
      }
    }
  }
  .PDatePicker-input,
  .ant-calendar-picker-input,
  .multi-datepicker-input {
    @media (max-width: 599px) {
      font-size: 16px;
      padding: 5px;
    }
  }
}
.search-form-wrapper {
  .passengers-options-holder-card {
    background: #fff;
    border-radius: 4px;
    padding: 10px;
    border: 1px solid $border-color;
    @media (min-width: 600px) {
      padding: 20px;
      box-shadow: $boxshadow1;
    }
    label {
      color: inherit;
    }
  }
  .hotel-search-btn {
    height: 45px;
  }
}
.body .close-autocomplete-btn,
.body .close-datepicker-btn {
  display: none;
  min-width: 0;
  background-color: transparent;
  padding: 0;
  position: fixed;
  bottom: 15px;
  right: 50%;
  margin-right: -20px;
  .itours-icon{
    position: static;
    width: 40px;
    height: 40px;
  }
  @media (max-width: 599px) {
    display: inline-block;
    margin-bottom: 15px;
  }
}
@media (max-width: 599px) {
  @supports not (-webkit-touch-callout: none){
    .body .autocompleteFeild.is-focused {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: #fff;
      z-index: 10;
      padding: 15px;
      overflow-y: auto;
      .autocompleteText {
        width: calc(100vw - 30px);
      }
      .form-input {
        border-radius: 4px;
      }
    }
  }
}
@keyframes fadeUp {
  from {opacity: 0;}
  to {opacity: 1;}
}