@import "./base";

.body.ayanair-theme{
  .purple{
    color: $ayanAirRed;
  }
  .footer {
    font-size: 13px;
    .footerblock-link{
      font-size: 13px;
    }
  }
  .font-14 {
    font-size: 14px;
  }
  .select-passenegers-block {
    font-size: 14px;
    .passenger-input{
      vertical-align: middle;
      width: 20px;
    }
  }
  .language-AR {
    font-family: 'naskh', sans-serif;
  }
  .button.red-btn, .button[type=submit].red-btn {
    background: #e51444;
  }
  background-color: #f9f9f9;
  
  &.arabic {
    font-family: shabnam, sans-serif;
  }
  .search-route-item .date-block{
    flex: 0 0 149px;
    .multi-datepicker-input{
      height: 45px;
      border-radius: 4px;
    }
  }
  .home-tabs-holder .home-tab {
    min-width: 130px;
  }
  .Home.hotel .naminTravel-search-block {
    @media screen and (min-width: $medium ){
      margin-top: 42px;
    }
  }
  .home-search-section{
    @media screen and (min-width: $medium ){
      background-position: center bottom;
    }
  }
  .Home.flight .home-search-section{
    background-position: center;
  }
  .sort-next-prev-block {
    @media screen and (min-width: $medium ){
      margin-top: -55px;
    }
  }
  .namin-banner-subslogan{
    font-size: 18px;
    margin-bottom: 25px;
  }
  .namin-banner-slogan {
    line-height: 20px;
    font-size: 34px;
    margin-top: 42px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .namin-banner-slogan,.namin-banner-subslogan {
    color: #fff;
    text-shadow: 0 0 5px #00000061;
    text-align: center;
  }

  .mobile-tab-hotel-flight{
    @media (max-width:$medium_){
      display: none;
    }
    .home-tab{
      text-transform: uppercase;
      background-color: rgba(0, 0, 0, 0.35);
      font-weight: 600;
      color: #ffffff78;
      &.home-active-tab{
        background: #f9f9f9;
        @media (min-width:$medium){
          border: 1px solid $ayanAirRed;
          border-bottom: 0 none;
          background-color: rgba(0, 0, 0, 0.6);
          color: #fff;
          position: relative;
          &::before,&::after{
            content: "";
            display: block;
            position: absolute;
            background-color: $ayanAirRed;
            bottom: 0;
            height: 1px;
            width: 100vw;
          }
          &::before{
            left: 100%;
          }
          &::after{
            right: 100%;
          }
        }
      }
    }
  }
  .account-links{
    .link-style-btn{
      @media (max-width:$medium_){
        display: block;
      }
    }
  }
  .ticket-style-section:first-child:after, .ticket-style-section:first-child:before{
    background-color: #f9f9f9;
  }
  .newletter-section {
    .has-error .ant-form-explain, .has-error .ant-form-split {
      color: red;
    }
  }
 
  .itours-modal .modal-overlay,.overlay,.full-container,.accountWindow,.loading-full-page {
    background: #101328cc;
    backdrop-filter: blur(1px);
  }
  // .loading-logo {
  //   width: 200px;
  // }
  main.main-content {
    min-height: calc(100vh - 347px);
    background: #fff;
  }
  @media (min-width:$medium){
    .home-search-section.has-dark-layer::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(0, #00000094, #0000006b, transparent);
    }  
  }
  .naminTravel-search-block {
    .search-form-wrapper, .mobile-tab-hotel-flight {
      position: relative;
      z-index: 1;
      @media (max-width:$medium_){
        margin-bottom: 15px;
      }
    }
    // .search-form-wrapper {
    //   background-color: transparent;
    //   padding: 0;
    //   .search-form-inner-wrapper{
    //     @media (min-width:$medium ){
    //       background-color: #01357e;
    //       padding: 15px 15px 0;
    //       @include border-radius(4px);
    //     }
    //   }
    // }
  }
  .slider-text-inner-wrapper {
    @media (max-width:$small_){
      display: none;
    }
    @media (max-width:$medium_){
      .slider-subtitle,.slider-title-text{
        color: #555;
        text-shadow: none;
      }
    }
    padding: 100px 0 30px;
    width: 650px;
    text-align: center;
    margin: 0 auto;
    .slider-subtitle,.slider-title-text{
      color: #fff;
      text-shadow: 1px 1px 5px #000000a8;
      transition: 0;
    }
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    .slider-title {
      font-size: 45px;
      font-weight: bold;
      .slider-title-text{
        position: absolute;
        bottom: 0;
        transition: all .2s linear .2s;
        left: 0;
        right: 0;
        &:not(.active) {
          visibility: hidden;
          opacity: 0;
          bottom: 5px;
          transition: all .1s linear;
        }
      }      
    }
    .slider-subtitle {
      font-size: 25px;
      font-weight: 600;
      width: 100%;
      .slider-subtitle-text{
        position: absolute;
        top: 50%;
        transition: all 0.2s linear 0.4s;
        left: 30px;
        right: 30px;
        transform: translate(0, -50%);
        line-height: 1;
        &:not(.active) {
          visibility: hidden;
          opacity: 0;
          top: 40%;
          transition: all .1s linear;
        }
      }      
    }
  }
  .adv-item {
    margin: 0 10px;
    height: 200px;
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    .adv-title {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(0deg, #000000db, transparent);
      font-weight: bold;
      color: #fff;
      font-size: 24px;
      text-align: center;
      padding: 25px 15px;
      margin: 0;
      text-transform: uppercase;
    }
  }
  .namin-adv-section {
    background: #fff;
    padding: 35px 0;
    @media (max-width:$small_){
      display: none;
    }
  }
  .destination-section-title {
    color: #033479;
    text-align: center;
    font-weight: 600;
    font-size: 25px;
    @media screen and (min-width: $medium ) {
      font-weight: bold;
      font-size: 30px;
    }
  }
  .namintravel-amazing-offer-title{
    color: #717171;
    font-weight: 600;
    font-size: 40px;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 30px;
    @media screen and (min-width: $large) {
      margin-bottom: 60px;
    }
  }
  .newsletter-form{
    @media screen and (max-width: $medium_) {
      margin-top: 25px;
      max-width: 100%;
    }
  }
  .namin-destinations-section {
    padding: 60px 0;
    @media screen and (max-width: $medium_) {
      padding: 25px 0;
      background-color: #fff;
    }
  }
  .namin-destinations {
    flex-wrap: wrap;
    @media (max-width:$medium_) {
      padding: 0;
      justify-content: center;
      .destination-block{
        flex-basis: 50% !important;
        flex-shrink: 0 !important;
        flex-grow: 0 !important;
        margin-bottom: 25px;
      }
    }
    display: flex;
    padding: 0 25px; 
    margin: 25px 0 0;
    @media (min-width : $medium){
      padding: 0 50px; 
      margin: 50px 0;
    }
    .destination-block {
      flex: 1;
      position: relative;
      &::after{
        content: "";
        display: block;
        position: absolute;
        right: 100%;
        top: 0;
        bottom: 0;
        background-color: #c6c6c6;
        width: 1px;
      }
      .block-title {
        padding: 0 15px;
        font-weight: bold;
        font-size: 22px;
        color: #717171;
      }
      .dest-country-title{
        font-weight: 600;
        font-size: 18px;
        color: #111;
        margin-bottom: 0;
      }
    }
    .dest-item-link {
      display: block;
      color: #888;
      line-height: 20px;
    }
    .dest-country-item {
      margin-bottom: 15px;
      padding: 0 15px ;
    }
  }
  .quick-access-block{
    border: 0 none;
    line-height: 20px;
    .footer-subtitle {
      font-weight: 600;
      font-size: 14px;
      margin-top: 10px ;
      margin-bottom: 0;
    }
  }
  .home-search-section {
    .slider-next-btn,.slider-prev-btn{
      padding: 0 ;
      flex-shrink:0;
    }
    @media (min-width:$large) {
      min-height: calc(100vh - 90px);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
  .slider-next-btn,.slider-prev-btn,.namin-adv-section .slick-arrow {
    line-height: 30px;
      height: 30px;
      width: 30px;
    background-color: #fff;
    cursor: pointer;
    &:hover{
      background-color: #f1f1f1;
    }
    min-width: 0;
    border-radius: 50%;
    border: 0 none !important;
    padding: 5px;
    box-shadow: 0 0 7px 1px #0000004d;
    outline: none !important;
    img{
      width: 15px;
    }
    &.slick-disabled{
      opacity: 0;
      visibility: hidden;
    }
  }
  .slider-subtitle-and-arrows {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  

  .namin-login-currency-holder .itours-select{
    border: 0 none;
    outline: none !important;
    cursor: pointer;
    background: none;
    color: #fff;
    font-weight: 400;
    option{
      color: $naminTravelDarkBlue;
    }
  }

  @media (max-width:$small_){
    .logo +.menu-toggle-btn {
      position: absolute;
      left: 10px;
    }
    .namin-login-currency-holder {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: end;
      padding-right: 5px;
      justify-content: space-between;
      height: 100px;
      padding-bottom: 5px;
      padding-top: 5px;    
      & > div{
        line-height: 20px;
        padding: 0;
        margin: 0;
        text-align: right;
        .itours-select{
          padding: 0;
          font-size: inherit;
          height: auto;          
        }
      }
    

      // &:not(.account-links){
      //   top: 0;
      // }
      // &.account-links{
      //   bottom: 5px;
      //   right: 15px;
      //   a.link-style-btn{
      //     display: block;
      //     margin-bottom: -6px;
      //   }
      // }
    }
  }

  .main-menu .menu-item{
    text-align: right;
    .itours-icon{
      display: none;
    }
  }
  .ayanair-header{
    background-color: #fff;
    box-shadow: 0 0 6px 0 #00000045;
    position: relative;
    .menu-toggle-btn {
      align-self: flex-end;
    }
    .ayan-logo-link{
      width: 80px;
      display: block;
      @media (max-width:$medium_){
        position: absolute;
        left: 50%;
        width: 80px;
        margin-left: -40px; 
        top: 50%;
        margin-top: -40px;   
      }
    }
    .ayanair-header-sides {
      display: flex;
      justify-content: space-between;
      padding: 7px 0;
      align-items: stretch;
      min-height: 90px;
      position: relative;
      @media (min-width:$medium){
        align-items: center;
      }
    }
    .ayanair-end-header{
      font-size: 14px;
      .account,.profile-and-logout {
        .ayan-outline-btn{
        
          @media (max-width:$medium_){
            margin: 0;
            display: inline-block;
          }
          @media (max-width:$small_){
            border: 0 none;
            padding: 0;
            min-width: 0;
          }
        }        
      }
    }
    .ayanair-end-header,.ayanair-start-header{
      display: flex;
      font-size: 14px;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      gap: 5px;
      @media (min-width:$medium){
        flex-direction: row;
      }
    }
    .ayanair-start-header{
      @media (min-width:$medium){
        gap: 50px;
      }
    }
    
    nav.ayanair-navigation {
      align-self: flex-end;
    }

    .header-modules-menu .link-item{
      color: #717275;
      font-size: 14px;
      font-weight: 600;
      .itours-icon{
        width: 25px;
        height: 25px;
        position: relative;
        top: 3px;
      }
    }
    .main-menu .menu-item {
      position: relative;
      &.has-divider::after{
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        background-color: #e51444;
        top: 1px;
        bottom: 5px;
      }
      a{
        color: #717275;
        font-size: 12px;
        font-weight: 600;
        border: 0 none;
      }
      &.active a{
        color: #e51444;
      }
    }
    .currency-acount {
      .link,.link-style-btn {
        color: #717275;
        font-weight: 600;
        @media (max-width:$small_){
          margin: 0;
        }
        .itours-icon.profile {
          @media (max-width:$small_){
            margin-bottom: 5px;
          }
        }
      }      
    }









    .profile-and-logout {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @media (min-width:$medium){
        flex-direction: row;
      }
    }
    .namintravel-header-sides {
       padding: 15px 0;
       .namintravel-header-1 > .menu-toggle-btn {
         position: absolute;
         left: 0;
         top: 50%;
         z-index: 1;
         margin-top: -8px;
         .menu-btn-bar{
           background-color: #fff;
         }
       }
       .logo{
         width: 70px;
         margin-bottom: 20px;
       }
       .itours-select.currency-select {
         background: transparent;
         color: #fff;
         border: 0 none;
         appearance: none;
         outline: none;
         height: auto;
         font-size: 14px;
         padding:0 10px;
        //  margin-right: 10px;
         text-align: center;
         option{
           background-color: #fff;
           color: #222;
           padding: 0 10px;
         }
         @media (max-width:$medium_){
           padding: 0;
           margin: 0;
           width: auto;
         }
       }
       .link,.link-style-btn{
         color: #fff;
         font-size: 14px;
         white-space: nowrap;
        //  @media (min-width:$medium){
        //    margin-left: 0;
        //    margin-right: 15px;
        //  }
       }
       @media (max-width:$medium_){
         text-align: center;
         .currency-acount {
           position: absolute;
           right: 0;
           top: 0;
           bottom: 0;
           z-index: 1;
           text-align: right;
           display: flex;
           flex-direction: column;
           justify-content: center;
           align-items: end;
           .link-style-btn,.link{
             display: block;
             margin: 0;
             text-align: right;
           }
         }
       }
       @media (min-width:$medium){
         padding: 20px 0;
         display: flex;
         justify-content: space-between;
         align-items: center;
         .logo{
           width: 65px;
           margin-bottom: 0;
         }
         .namintravel-header-1 {
           display: flex;
         }
         .ayan-logo-link {
           margin-right: 35px;
         }
         .namintravel-header-2 {
           display: flex;
           align-items: center;
         }
         .currency-acount {
           display: flex;
         }
       }
     }
  }


  .button,
  .button[type="submit"] {
    @include border-radius(5px);
    @include transition(all 0.2s);
    &.small-btn{
      line-height: 15px;
      padding: 5px 10px;
      font-size: 13px;
    }
    &.medium-btn{
      line-height: 16px;
      padding: 7px 10px;
      font-size: 13px;
    }
    &.namin-yellow-btn{
      background: $ayanAirYellow;
      color: #222;
      &:hover {
        @media (hover: hover) {
          background: $ayanAirYellow;
        }
      }
      &:focus{
        background: $ayanAirYellow;
      }
    }
    &.light-grey-btn{
      background-color: gainsboro;
      color: #3a3a3a;
      &:hover{
        background-color: rgb(185, 185, 185);
      }
    }
    &.namin-blue-btn,
    &.color-1-btn,
    &.blue-btn{
      background: #00008b;
      color: #fff;
      &.text-white{
        color: #fff;
      }
      &:hover {
        @media (hover: hover) {
          background: #0000bb;
        }
      }
      &:focus{
        background: #0000bb;
      }
    }
  }

  // .home-search-section {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   margin-top: 0;
  //   @media screen and (min-width: $medium){
  //     padding-bottom: 150px;
  //     min-height: 450px;
  //   }
  // }
  // .Home.flight .naminTravel-search-block {
  //   @media screen and (min-width: $medium){
  //     margin-top: -150px;
  //   }
  // }
  // .Home.hotel .naminTravel-search-block {
  //   @media screen and (min-width: $medium){
  //     margin-top: -80px;
  //   }
  // }

  .triptype-radio-label{
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: 600;
  }
  .namintravel-amazing-offer-inner {
    background-color: #fde68a;
    background-image: url("../image/namintravel/suitcase-rtl.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center right;
    border-radius: 10px;
    max-width: 850px;
    margin: auto;    
  }

  .namintravel-amazing-offer-section{
    padding: 60px 0 35px;
    @media screen and (max-width: $medium_) {
      display: none;
    }
  }
  .namintravel-amazing-offer-list {
    color: #01357e;
    font-weight: 600;
    font-size: 17px;
    margin: 0;
    list-style-type: none;
    padding: 25px 0;
    li::before{
      content: "";
      display: inline-block;
      width: 8px;
      height: 10px;
      background-color: #b45a5b;
      margin-left: 7px;
      clip-path: polygon(100% 0%, 0 50%, 100% 100%);
    }
  }
  .Home {
    background: #f9f9f9;
  } 
  .remove-return-btn{
    background-color: #e51444;
    border-radius: 0 3px 0 0;
  }
  .itinerary-label{
    font-weight: bold;
  }
  .yellow{
    color: $naminTravelYellow;
    &.yellow.link-style-btn{
      color: $naminTravelYellow;
      &:hover,&:focus{
        color: $naminTravelYellow;
      }
    }
  }
  .multy-dest-route .itinerary-label .line{
    height: 1px;
    &.yellow{
      background-color: $naminTravelYellow;;
    }
  }
  .add-destination-holder .link-style-btn{
    color: #555;
    font-weight: 600;
    @media screen and (min-width: $medium) {
      color: #fff;
    }
    &:hover,&:focus{
      color: #fff;
    }
    .itours-icon{
      background-color: #fff;
      border: 1px solid #ccc;
    }
  }
  .okr-bg{
    background-color: #fbe6ad;
  }
  .side-top-text {
    text-align: justify;
    padding: 5px 15px;
    color: $naminTravelDarkBlue;
    font-weight: 600;
    font-size: 16px;
    border-radius: 5px;
  }
  .searchShow {
    border-bottom: 0 none;
  }
  .flight.search-result-page .searchShow {
    @media (min-width:$small){
      margin: 25px 0 20px;
    border: 1px solid $ayanAirRed;
    padding: 37px 15px;
    }
  }
  .filter-header{
    background-color: #00008b;
    color: #fff;
    padding: 10px;
  }

  .filters-holder .okr-bg {
    padding: 2px 7px;
    display: inline-block;
    border-radius: 4px;
    color: $naminTravelDarkBlue;
  }
  .stop-message-in-allSegments,
  .stop-message .stop-info {
    background: #f1dce1;
    text-align: left;
    color: #222;
  }
  .button.results-sort-btn{
    color: $naminTravelDarkBlue;
  }
  .included-baggage-mark{
    background-color: #00008b;
    color: #fff;
    border-color: #00008b;
  }
  .flight.search-result-page .flight-result-item{
    transition: 0;
    color:inherit;
    .gray-a,.baggage-block {
      color: #aaa;
    }
    &:hover {
      outline: 1px solid #903d50;
      border-color: #903d50;
    }
  }
  @media (min-width:$small){
    .flight.search-result-page .results-sorting-block {
      margin-top: -52px;
    }
    .flight-avails-row{
      padding-top: 0;
    }
  }
  .ticket-style-section{
    border: 2px solid #964b61;
    &:first-child{
        border-bottom: 0 none;
        &::before{
        border: 2px solid #964b61;             
        }
        &::after{
        border: 2px solid #964b61;
        }
    }
    &:not(:first-child){
      border-top:1px dashed #964b61;
    }
  }
  .hint-inner {
    background-color: #f1dce1;
    color: #ae5364;
    font-weight: 400;
  }
  .summary-flight-type-row .ant-tag{
    background-color: #fbe6ad;
    color: $naminTravelDarkBlue;
    font-weight: 600;
    font-size: inherit;
    border: 0 none; 
    padding: 2px 7px;
    display: inline-block;
    border-radius: 4px;
  }
  .main-content .ant-collapse-header .passenger-type-title{
    color: $naminTravelDarkBlue;
  }
  .radio-btn-label.type-2 {
    .radio-btn-label-text::after{
      border-color: $naminTravelDarkBlue;
    }
    input[type=radio]:checked + .radio-btn-label-text::after {
      background-color: $naminTravelDarkBlue;
    }
  }
  .wheelchair-block-title{
    background-color: $ayanAirYellow;
    color: #222;
  }
  .wheelchair-block label.checkbox-label{
    color: $naminTravelDarkBlue;
    &::before{
      border-color: $naminTravelDarkBlue;
    }
  }
  .ant-steps-item-process .ant-steps-item-icon{
    background-color: $naminTravelYellow;
    border-color: $naminTravelYellow;
    .ant-steps-icon{
      color: $naminTravelDarkBlue;
    }
  }
  .ant-steps-item-finish .ant-steps-item-icon{
    background-position: 74.2% 20%;
  }
  .hotelResult .button.results-sort-btn:not(.disable){
    background-color: #eaeef0;
    border-color: $naminTravelDarkBlue;
    @media screen and (min-width: $medium) {
      background-color: #e8cad2;
      border-color: #e8cad2;
    }
  }
  .link-style-btn{
    color: $naminTravelDarkBlue;
    &:hover{
      color: #116ae7;
    }
  }
  .map-btn-holder {
    margin-bottom: 15px;
  }
  .blue-border-right {
    @media screen and (min-width: $small) {
      border-right: 2px solid $naminTravelDarkBlue;
    }
  }  
  .hotel-details {
    .normal-title,.title {
      color: #000;
    }    
  }
  .hotel-summary-card {
    border: 2px solid #964b61;
    .card-title{
      background-color: $naminTravelDarkBlue;
      color: #fff;
    }
  }
  .ant-tag-red {
    @media screen and (min-width: $medium) {
      color: #af1d40;
      background: #e5cbd0;
      border-color: #e5cbd0;
    }
    font-size: 14px;
  }
  .ant-tag-green {
    color: #4caf50;
    background: #e2efd3;
    border-color: #e1f1cf;
    font-size: 14px;
  }
  .radio-item {
    .radio-label .radio-mark{
      border-color: $naminTravelDarkBlue;
    }
    .custom-radio:checked + .radio-label .radio-mark:before{
      background-color: $naminTravelDarkBlue;
    }
  }
  .top-color-border.orange {
    border-top-color: #964b61;
    .colored {
      color: #964b61;
      font-weight: 600;
    }
  }
  .has-fake-magnifirer-icon:before{
    background-color: $naminTravelDarkBlue;
  }
  .Pagination .page-btn{
    &:not(.disabled):not(.clicked){
      color:  #bbb;
      &:hover{
        background-color: $naminTravelDarkBlue;
        color: #fff;
      }
    }
    &.next-btn:not(.disabled),
    &.prev-btn:not(.disabled){
      color: $naminTravelDarkBlue;
    }
  }

  // .tracking {
  //   @media screen and (max-width: $medium) {
  //     left: 0;
  //     &::before{
  //       right: 50%;
  //     }
  //   }
  // }
  .blue, .color-1{
    color: $naminTravelDarkBlue;
  }
  .blue-border{
    border-color: $naminTravelDarkBlue;
  }
  .bg-yellow{
    background-color: $naminTravelYellow;
  }
  .bg-purple{
    background-color: #e51444;
  }
  .accountWindow .Form .login-tabs-holder .tab-btn{
    background-color: $ayanAirRed;
    color: #fff;
    &.active{
      background-color: #fff;
      color: #000;
    }
  }
  .profileContainer .tab-holder {
    .tab-s{
      flex: 0 0;
      white-space: nowrap;
      border: 0 none;
      padding: 0 15px;
      border: 1px solid #ddd;
      border-bottom: 0 none;
      &.active-tab-s{
        background: #feba01;
        color: #00347d;
      }
      @media screen and (min-width: $medium) {
        background: #feba01;
        border-radius: 10px 10px 0 0;
        color: #00347d;
        box-shadow: 2px -2px 3px 0 #00000012;
        margin-bottom: -1px;
        margin-right: 5px;
        &.active-tab-s{
          background: #fff;
        }
      }
    }
    &+.card-padding{
      border: 1px solid #ddd;
      border-radius: 0 0 7px 7px;
      @media screen and (min-width: $medium){
        box-shadow: 0 0 7px 0 #00000024;
        border-radius: 0 7px 7px 7px;
      }
    }
  }
  .profileContainer .normal-title .itours-icon {
    display: none;
  }
  @media screen and (min-width: $small) {
    .change-password-form .form-group {
      display: flex;
      .form-label{
        white-space: nowrap;
        flex: 0 0 170px;
        font-weight: 600;
        font-size: inherit;
        &+div{
          flex: auto;
        }
      }
    }
  }
  .profileContainer .itours-table {
    th {
      background: #00347d;
      color: #fff;
      font-weight: 600;
    }
    td,th{
      border: 1px solid #999;
      text-align: center;
    }
  }
  .content-link{
    color: $naminTravelDarkBlue;
    &:hover{
      color: #116ae7;
    }
  }
  .error-box{
    max-width: 400px;
    top: 50%;
    left: 50%;
    position: absolute;
    width: calc(100% - 20px);
    @include transform(translate(-50%, -50%));
    .box-header {
      background: #f03f4f;
      color: #fff;
      font-weight: bold;
      font-size: 20px;
      padding: 10px 15px;
      border-radius: 10px 10px 0 0;
    }
    .box-main {
      padding: 35px 15px;
    }
  }
  .reverse .passengerInformation.margin-bottom .table-row-group .table-row.table-head {
    background: $naminTravelYellow;
    &>div{
      font-weight: 600;
      white-space: nowrap;
      color: $naminTravelDarkBlue;
    }
  }
  .room-guests .form-group .form-select.no-border-select {
    color: #666;
    background-color: #f1f1f1;
  }  
  .search-form-wrapper .room-guests {
    padding: 0px 10px;
    background-color: #f1f1f1;
    border: 0 none;
    border-radius: 5px;
    &:not(:first-child){
      margin-top: 23px;
    }
    .remove-room-btn{
      border-radius: 50%;
      height: 21px;
      width: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -10px;
    }
    .children-ages-block {
      min-height: 25px;
    }
  }
  .passengers-options-holder{
    .plus-blue-icon{
      background-color: $naminTravelYellow;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      border: 3px solid;      
    }
  }
  .loading-full-page{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(135deg, #13064e, #46108a);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    
    background: url('../image/ayanair/loading-bg.jpg');
    background-position: center bottom;
    background-size: cover;

    .description{
      color: #fff;
    }
    .loading-section.text-center {
      background-color: transparent;
      padding: 0;
      min-width:0;
    }
  }
  .bg-pink {
    background: #b25067;
  } 
  .airline-filter-with-list {
    @media screen and (min-width: $small) {
      display: flex;
      align-items: center;
    }
    @media screen and (max-width: $small_) {
      & > .airline-filter{
        margin: 15px 0 0;
      }
      .styled-list{
        text-align: center;
      }
    }
    .styled-list{
      flex: 0 0 270px;
      &+.airline-filter {
        flex: auto;
        background: #fff;
        padding: 5px 10px;
        border: 1px solid #ddd;
        overflow: auto;
        .airline-table-holder{
          border: 0 none;
          overflow: unset;
        }
      }
    }

  } 
  .airline-filter-holder .cell {
    background: #ededed;
    + .cell {
      background-color: #dcdcdc;
    }
  }
  .bg-gray2{
    background-color: #dcdcdc;
    .styled-list li::before{
      border-color: #b25067;
    }
  }

  
  
  @media screen and (min-width: $small){
    .hotel-footer .namin-travel-hotel-item-footer{      
      display: flex;
      flex-direction: column;
      align-items: end;
      .prices-holder {
        order: 1;
      }
      .price-for-night{
        margin-bottom: 0;
        order: 2;
      }
      .hotel-detail-btn-holder{
        order: 3;
      }
      .button{
        order: 4;
      }
    }
  }
  .hotel-details-modal .modal-footer .red-btn {
    background: $naminTravelDarkBlue;
  }
  .getpassenger-page,.reserve-page,.contact-us,.reserve-details-page,.terms-page {
    background: #f9f9f9;
  }    
  @media screen and (max-width: $small_) {
    .profile-tab-and-contents-wrapper .itours-table td{
      border: 0 none;
      &:not(:last-child){
        border-bottom: 1px dotted #ccc;
      }
    }    
    .accountWindow {
      background: #f9f9f9;
      .closeBtn {
        filter: brightness(0.5);
      }
    }
    .contact-us .page-title{
      text-align: center;
      border: 0 none;
    }
    #hotel_details > .box-border{
      border: 0 none;
    }
    .flight-loading-sec{
      border: 0 none;
      border-bottom: 2px dashed #999;

    }
    .na-res-pad{
      margin-left: 10px;
      margin-right: 10px;
      padding-left: 0;
      padding-right: 0;
    }    

    .bg-white.responsive-bg-transparent{
      background-color: transparent;
    }
    
    .select-room-wrapper{
      padding: 0 15px;
      &>.card{
        margin-bottom: 35px;
      }
    }
    .select-room-title.normal-title {
      text-align: center;
      font-weight: 600;
      border-bottom: 2px dashed #999;
      margin: 0 10px 10px;
      padding-bottom: 15px;
    }
    .hotel-detail-searchholder .bg-dark-blue{
      background-color: $naminTravelDarkBlue;
    }
    .hotel-detail-searchholder .search-form-header{
      text-align: center;
      .button{
        margin: 25px auto 10px;
        background-color: $naminTravelDarkBlue;
      }
    }
    .hotel-head-info .hotel-title {
      text-align: center;
    }
    .namin-travel-hotel-item-footer {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      & > *{
        flex: 0 0 50%;
        &.show-on-map-btn{
          flex: 0 0 100%;
        }
      }
      .hotel-detail-btn-holder{
        text-align: left;
        .link-style-btn{
          color: #fff;
          background-color: $naminTravelDarkBlue;
          padding: 3px 10px;
        }
      }
      .prices-holder {
        text-align: left;
      }
      .price-for-night {
        margin-bottom: 10px;
      }
    }    
    .hotel-result-item{
      border-top: 0 none;
      margin-bottom: 35px;
      .hotel-image{
        border: 1px solid #bbb;
      }
    }
    .detail-section{
      border-bottom: 2px dashed #999;
    }
    .flight-avails-row{
      padding-top: 0;
    }
    .price-and-book {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      button.button.select-flight-btn{
        padding: 5px 15px;
        min-width: 180px;
      }
      .price {
        color: #000;
        font-size: 17px;
      }
    } 
    .flight-footer-top-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .flight-type-and-seats {
        flex: 0 0 180px;
        display: flex;
        justify-content: space-between;
      }
    }     
  }
  @media screen and (max-width: $medium_) {
    .profile-tab-and-contents-wrapper{
      box-shadow: 1px 1px 8px 0 #0000003d;
      border-radius: 4px;
    }
    .hotel-details .flight-result-item{
      border: 0 none;
      border-bottom: 2px dashed #999;
    }
  
    .filters-holder{
      padding:70px 0 0;
      .filter-header {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        line-height: 50px;
        z-index: 1;
      }
    } 
    .filter-responsive-btn{
      z-index: 2;
    } 
    .flightDetails .flight-detail-main .itours-tab-holder .itours-tab-list{
      display: flex;
      justify-content: space-between;
      background: transparent;
      .itours-tab-btn{
        background-color: $naminTravelYellow;
        color: $naminTravelDarkBlue;
        margin: 0 2px;
        flex-grow: 1;
        padding: 10px 5px;
        line-height: 18px;
        border-radius: 0;
        &.active{
          background-color: #fff;
        }
      }
    }
    .hotelResult .page-title {
      text-align: center;
      padding: 0;
      border: 0 none;
    }
  }
  .image-404 {
    max-width: 700px;
    width: 100%;
    margin: 35px auto;
    display: block;
  }
  @media screen and (min-width: $large){
    .search-submit-col{
      width: 10%;
    }
    .passengers-and-cabinClass{
      width: 35%;
    }
    .search-main-col{
      width: 55%;
    }
    .passengers-select-only{
      width: 22.8%;
      .selected-passengers-input {
        height: 90px;
      }
    }
    .naminTravel-search-block .float-row.half-gap{
      margin-left: -2.5px;
      margin-right: -2.5px;
      [class*=col-]{
        padding-left: 2.5px;
        padding-right: 2.5px;
      }
    }
    .hotel-search-form-holder .button-holder{
      padding-left: 5px;
      flex: 0 0 178px;
    }
    .hotel-search-form-holder .hotel-search-form-main{
      flex: auto;
    }
  }
  .search-form-wrapper .fake-return-date{
    font-size: 11px;
    height: 45px;
    border-radius: 4px;
    background-image: none;
  }
  .searchBtn,.thickSearchBtn{
    border-radius: 4px;
    height: 45px;
    padding: 0 2px;
    .btn-text{
      display: inline;
    }
    
  }
  .selected-passengers-input {
    border-radius: 4px;
    min-width: 240px;
    margin-top: 0;
  }

  @media screen and (max-width: $medium_){
    .header-modules-menu {
       display: none;
    //   display: flex;
    //   justify-content: space-between;
    //   .link-item{
    //     margin: 0;
    //     padding: 0 10px 5px;
    //     .itours-icon{
    //       display: none;
    //     }
    //   }
    }
  }

  .next-previous-group{
    .button{
      font-weight: 600;
      line-height: 13px;
      @media screen and (min-width: $large) {
        width: 170px;
      }
    }  
  }
  .autocompleteText .suggestList ul li .link .autocomplete-list-icon {
    top: 11px;
  }

  .passengers-options-holder .link-style-btn.white {
    color: #555;
    font-weight: 600;
    @media screen and (min-width: $medium) {
      color: #fff;
    }
    .plus-simple-icon{
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 50%;
    }
  }
  .rounded{
    border-radius: 15px;
  }

  .section-padding {
    padding: 35px 0;
    @media screen and (min-width: $medium) {
      padding: 70px 0;
    }
  }
  .slick-dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    li {
      button{
        width: 13px;
        height: 13px;
        border: 1px solid #00000063;
        background: #fff;
        border-radius: 50%;
        &::before{
          display: none;
        }
      }
      &.slick-active button{
        background-color: $purple;
      }
    }
  }

  .price.tag {
    background: #fff;
    color: red;
    padding: 2px 7px;
    line-height: 20px;
    font-weight: bold;
    border-radius: 5px;
  }   
  .ayanair-destinations{
    margin-left: -7px;
    margin-right: -7px;
    display: grid;
    grid-template-columns: 50% 50%;
    @media screen and (min-width:$large){
      &:hover {
        .destination-item img {
          opacity: .8;
          filter: blur(2px);
        }
        .destination-item:hover img {
          opacity: 1;
          filter: blur(0);
        }
      }
      grid-template-columns: 25% 25% 25% 25%;
    }

    @media screen and (max-width:$small_) {
      display: block;
      div.destination-item{
        height: auto;
        margin-bottom: 1rem;
        img{
          height: auto;
        }
      }
    }

    .destination-item{
      position: relative;
      display: flex;
      height: 250px;
      padding: 7px;
      @media screen and (min-width:$large) {
        &:hover img{
          transform: scale(1.05);
        }
        height: 350px;
        &:nth-child(1){
          grid-column: 1 / span 2;
          grid-row: 1;
        }
        &:nth-child(2){
          grid-column: 3;
        }
        &:nth-child(4){
          grid-column: 2 / span 2;
          grid-row: 2;
        }
      }
      .button{
        position: absolute;
        top: 20px;
        left: 20px;
      }
    }
    .image-wrapper {
      height: 100%;
      width: 100%;
      display: flex;
      overflow: hidden;
      border-radius: 15px;
    }
    img{
      width: 100%;
      object-fit: cover;
      object-position: center;
      transition: all .3s ease-in-out;
    }
  }
  .main-footer{
    padding: 35px 0 5px;
    @media screen and (min-width:$large){
      padding: 70px 0 10px;
    }
  }
  .bottom-footer{
    background: transparent;
  }
  .social-links {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    @media screen and (max-width:$medium_){
      justify-content: center;
    }
  }
  .footer {
    color: #fff;
    background: #151b54;
    a,.footer-title{
      color: #fff;
    }
  }
  .baggage-filter-wrapper{
    background-color: #e51444;
    color: #fff;
  }

  .ayan-outline-btn {
    border: 1px solid #ddd;
    border-radius: 4px;
    line-height: 36px;
    padding: 0 10px;
    font-weight: 600;
    text-align: center;
    color: $ayanAirBlue;
    user-select: none;
    background-color: transparent;
    cursor: pointer;
    &:hover{
      background-color: #fafafa;
    }
  }

  .language-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .flag {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      object-fit: cover;
    }
  } 
  .languages-popup{
    left: -15px;
    right: -15px;
  }
  .home-tab {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    .svgIcon{
      display: block;
      fill: currentColor;
      margin:0;
    }
  }
  
  .select-passengers{
    flex-grow: 1;
  }
  .margin-bottom.search-main-col,
  .margin-bottom.passengers-and-cabinClass,
  .margin-bottom.search-submit-col {
    margin-bottom: 10px;
  }
  @media screen and (min-width:$small) {
    .home-search-section .search-form-wrapper{
      padding: 35px 0;
    }    
    .passengers-and-cabinClass {
      display: flex;
      flex-direction: row-reverse;
      gap: 5px;
    }
    .search-route-item .origin-destination-block{
      margin-right: 5px;
      display: flex;
      & > div {
        flex-grow: 1;
      }
      gap: 5px;
      .form-input{
        border-radius: 4px;
        height: 45px;
        margin-top: 0;
        background-image: none;
        padding-left: 15px;
        padding-right: 15px;
      }
      .autocompleteText .clear-autocomplete{
        right: 7px;
      }
      .swap-vert {
        right: 50%;
        margin-right: -13px;
        transform: rotate(90deg);
      }
    }
  }

  .styled-cabin-select {
    @media screen and (min-width:$medium){
      .custom-select-input{
        color: #fff;
        margin-left: 15px;
      }
    }
    font-weight: 600;
    margin-bottom: 15px;
    line-height: 28px;
  }
  .triptype-radio-group{
    flex-wrap: wrap;
  }
  .search-form-wrapper .search-airline-block .form-group .form-input{
    border-radius: 4px;
  }
  @media screen and (max-width:$small_){
    .search-airline-block{
      margin-bottom: 15px;
    }
  }
  .passengers-and-cabinClass > div {
    flex-grow: 1;
  }

  .ayanair-banner{
    box-shadow: 1px 1px 10px 0 #0000004f;
    border-radius: 10px;
    @media screen and (min-width:$small){
      border-radius: 20px;
    }
  }
  .ayanair-banner-section{
    padding-top: 35px;
    @media screen and (min-width:$small){
      padding-top: 70px;
    }
  }
  .newslettrModal{
    color: #333;
  }
  .ayanair-newsletter-section{
    .ayanair-newsletter-Inner{
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;

      .has-error .ant-form-explain, .has-error .ant-form-split{
        color: #ffc107;
      }
      .form-input,.button  {
        border-radius: 10px;
        height: 45px;
      }
      .form-input{
        flex: auto;
        width: 100%;
        @media screen and (min-width:$medium){
          width: 400px;
        }
      }
      .button{
        flex: auto;
        flex-grow: 0;
        margin: 0;
        text-transform: uppercase;
      }
      .inline-newsletter-form{
        margin: 15px 0;
        gap: 5px;
      }
      .ant-form-horizontal{
        @media screen and (max-width:$medium_){
          align-self: stretch;
        }
      }
    }
    background-color: #2878b5;
    color: #fff;

    .title{
      font-weight: 600;
      font-size: 25px;
      margin: 0;
    }
    padding: 15px 0;
    @media screen and (min-width:$small){
      padding: 35px 0 20px;
    }
  }

  &.arabic .ayanair-header .main-menu .menu-item a{
    font-size: 13px;
  }
  .footer-title{
    margin-top: 20px;
    font-size: 17px;
    font-weight: 600;
  } 
  .x-bordered-title {
    border-left: 4px solid $ayanAirBlue;
    border-right: 4px solid $ayanAirBlue;
    display: inline-block;
    padding: 0 10px;
    margin-bottom: 25px;
    margin-top: 20px;
    line-height: 1;
    .bold{
      display: block;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }
  .language-US .x-bordered-title .bold{
    letter-spacing: -1px;
  }



  .airline-table-holder{
    border: 0 none;
    padding-bottom: 5px;
    .airline-table-header {
      margin-top: 13px;
    }
    .airline-logo {
      width: 50px;
      height: 50px;
      background: #fff;
      box-shadow: 0px 0px 5px #0003;
      border-radius: 50%;
      object-fit: contain;
      padding: 2px;
      margin: 7px auto;
    }    
  }
  .airline-filter-item{
    margin: 0 3px;
    border: 0 none;
    position: relative;
    &:not(:last-child)::after{
      content: "";
      display: block;
      position: absolute;
      height: 40px;
      width: 1px;
      background-color: $style3Yellow;
      top: 10px;
      left: -3px;
    }
  }
  .airline-filter-wrapper {
    margin-top: 10px;
    background: #fff;
    padding: 5px 10px 10px;
    border-radius: 5px;
    border: 1px solid $style3Yellow;
    .ant-checkbox:not(.ant-checkbox-checked) .ant-checkbox-inner{
      background-color: #fff;
    }
    .cell {
      background: #c8c8c8;
      font-weight: 600;
      border-radius: 5px;
      & + .cell {
       background : #e6e6e6;
       margin-top: 5px;
      }
    }

    .airline-table-header .airline {
      height: 64px;
    }

  }
  .detail-flight img.airline,
  .summery-airline-logo img.airline-logo{
    width: 50px;
    height: 50px;
    background: #fff;
    box-shadow: 0px 0px 5px #0003;
    border-radius: 50%;
    object-fit: contain;
    padding: 2px;
  }

  .included-baggage-mark{
    display: flex;
    align-items: center;
    padding: 3px;
    .text{
      margin: 0 5px;
    }
    .svg-checkmark {
      background: #fff;
      fill: $ayanAirRed;
      width: 14px;
      height: 14px;
      border-radius: 50%;
    }
  }

}
//end of .body.ayanair-theme


.ayanair-home-contact {
  @media screen and (min-width:$medium){
    display: flex;
    justify-content: space-between;
    .item{
      max-width: 270px;
    }
  }
  direction: ltr;
  .item{
    a{
      color: inherit;
    }
    display: flex;
    align-items: center;
    line-height: 30px;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 20px;
    .icon{
      width: 30px;
      margin-right: 10px;
      @media screen and (min-width:$medium){
        width: 50px;
      }
    }
  }
}
.ayanair-newsletter-box {
  .newsletter-form{
    max-width: 550px;
    margin-top: 0;
    .form-group {
      .form-input,.button{
        height: 50px;
        border-radius: 10px;
      }
    }
  }

  background: #3d4a5d;
  padding: 2rem 1rem;
  @media screen and (min-width:$medium){
    padding: 2rem;
  }
  border-radius: 1rem;
  @media screen and (min-width:$medium){
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    .newsletter-labels{
      max-width: 50%;
    }
  }
  color: #fff;
  .title{
    font-size: 20px;
    font-weight: 600;
  }
  p{
    font-size: 14px;
  }
  
}

.next-previous-group{  
  @media screen and (min-width: $small) {
    .button{
      &:first-child {
        body.ayanair-theme:not(.ltr) & {
          border-radius:  0 10px 10px 0;
        }
        body.ayanair-theme.ltr & {
          border-radius: 10px 0 0 10px;
        }
      }
      &:last-child {
        body.ayanair-theme:not(.ltr) & {
          border-radius: 10px 0 0 10px;
        }
        body.ayanair-theme.ltr & {
          border-radius:  0 10px 10px 0;
        }
      }
    }
  }
 
}

.accountWindow {    
  .Form.naminTravel-register-form  {
    @media (min-width:$small){
      max-width: 650px;
      .form-group {
        .form-label{
          width: 150px;
          display: inline-block;
        }
        .form-input,.form-select,.select-input-pair-holder-outer{
          display: inline-block;
          width: calc(100% - 150px);
        }
      }
      button.button.full-width {
        width: calc(100% - 150px);
      }
      .validator-label{
        body:not(.ltr) & {
          margin-right: 150px;
        }
        body.ltr & {
          margin-left: 150px;
        }
      }
      
    }
  }
}
.header-modules-menu .link-item .itours-icon {
  width: 22px;
  height: 22px;
  vertical-align: text-bottom;
  margin-right: 1px;
}

.namin-sort-btn-group {
  display: inline-flex;
  gap: 3px;
  .btn{
    font-weight: 600;
    background: gainsboro;
    line-height: 13px;
    @media screen and (min-width: $large) {
      width: 170px;
    }    
    @media screen and (min-width: $small) {
      &:first-child {
        body:not(.ltr) & {
          border-radius:  0 10px 10px 0;
        }
        body.ltr & {
          border-radius: 10px 0 0 10px;
        }
      }
      &:last-child {
        body:not(.ltr) & {
          border-radius: 10px 0 0 10px;
        }
        body.ltr & {
          border-radius:  0 10px 10px 0;
        }
      }
    }

    &.active{
      background: #00008b;
      color: #fff;
    }
  }
}

.newsletter-form button {
  text-transform: uppercase;
}

.body.ayanair-theme.ltr{
  .search-form-wrapper .departure-date,
  .search-route-item .origin-destination-block{
    margin-right: 5px;
    &.multy-destination{
      margin-right: 0;
    }
  }
  .mobile-tab-hotel-flight .home-tabs-holder .home-tab{
    margin-right: 10px;
  }
  .search-form-wrapper .room-guests .remove-room-btn{
    right: 3px;
  }
  .namintravel-amazing-offer-list li::before{
    margin-left: 0;
    margin-right: 5px;
    clip-path: polygon(0 0%, 100% 50%, 0 100%);
  }
  .namintravel-amazing-offer-inner {
    background-image: url("../image/namintravel/suitcase.jpg");
    background-position: center left;   
  }
  .main-menu .menu-item{
    text-align: left;
  }

  .main-menu .menu-item::after{
    right: -15px;
  }
}

.body.ayanair-theme:not(.ltr){
  .search-form-wrapper .departure-date,
  .search-route-item .origin-destination-block{
    margin-left: 5px;
    &.multy-destination{
      margin-left: 0;
    }
  }
  .mobile-tab-hotel-flight .home-tabs-holder .home-tab{
    margin-left: 10px;
  }
  .included-baggage-mark {
    padding-top: 3px;
  }  
  .search-form-wrapper .room-guests .remove-room-btn{
    left: 3px;
  }
  .main-menu .menu-item::after{
    left: -15px;
  }
  .remove-return-btn {
    border-radius: 3px 0 0 0;
  }

  .profileContainer .tab-holder {
    .tab-s{
      @media screen and (min-width: $medium) {
        margin-left: 5px;
        margin-right: 0;
      }
    }
    &+.card-padding{
      @media screen and (min-width: $medium){
        border-radius: 7px 0 7px 7px;
      }
    }
  }
  


}


